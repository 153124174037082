import { Button, Divider, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const SiteSettingsPage: React.FC = () => {
    const { changeTitle } = useLayout();
    const [siteSetting, setSetSetting] = useState(null);
    const [isReady, setIsReady] = useState(false);

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();

    const handleSubmit = useCallback(
        async (data) => {
            const response = await apiGateway.put('/settings/site', data);

            if (response.status === HTTP.STATUS.SUCCESS) {
                localStorage.setItem('site:settings', JSON.stringify(data));
                toast({
                    title: 'Registro Atualizado',
                    description: 'Informações atualizadas com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });
            }
        },
        [toast]
    );

    useEffect(() => {
        changeTitle('Personalizar Site');
        apiGateway.get('/settings/site').then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setSetSetting(response?.data?.attributes || null);
            }
            setIsReady(true);
        });
    }, [changeTitle]);

    return (
        isReady && (
            <Form ref={formRef as any} onSubmit={handleSubmit}>
                <Flex width="100%" flexDirection="column" fontSize="12px">
                    <Flex width="100%" flexDirection="column" alignItems="center">
                        <Flex
                            width="100%"
                            maxWidth="800px"
                            flexDirection="column"
                            backgroundColor="white"
                            px="24px"
                        >
                            <Flex
                                width="100%"
                                py="16px"
                                justifyContent="space-between"
                                flexWrap="wrap"
                            >
                                <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                    <Flex
                                        width="100%"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Text mr="16px" fontSize="24px" color="purple.500">
                                            Defina as cores
                                        </Text>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="space-between"
                                            flexWrap="wrap"
                                        >
                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Cores Principais
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="primary"
                                                    label="Cor Principal"
                                                    size="sm"
                                                    isRequired
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting.primary
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>
                                            <Flex width="48%">
                                                <Input
                                                    name="accent"
                                                    label="Contraste (em relação a cor principal)"
                                                    size="sm"
                                                    isRequired
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting ? siteSetting.accent : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Cabeçalho de Contato
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-info"
                                                    label="Cor de Fundo"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-info']
                                                            : '#EFEFEF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-info-schedule"
                                                    label="Texto Horário de Atendimento"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-info-schedule']
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-info-icon"
                                                    label="Icones"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-info-icon']
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-info-phone"
                                                    label="Número de Telefones"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-info-phone']
                                                            : '#000000'
                                                    }
                                                />
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Cabeçalho
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-main"
                                                    label="Cor de Fundo"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-main']
                                                            : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-details"
                                                    label="Cor dos detalhes (textos e icones)"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-details']
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Menu Principal
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-nav"
                                                    label="Cor de Fundo"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-nav']
                                                            : '#FBEC51'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-nav-item"
                                                    label="Cor do Texto do Menu"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-nav-item']
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-nav-item-bg"
                                                    label="Cor de Fundo do Menu (Quando mouse passa por cima)"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-nav-item-bg']
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="header-nav-item-color"
                                                    label="Cor do Texto do Menu (Quando mouse passa por cima)"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['header-nav-item-color']
                                                            : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Botão Cidades Atendidas
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="dropdown-city-bg"
                                                    label="Cor de Fundo"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['dropdown-city-bg']
                                                            : '#E61077'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="dropdown-city-color"
                                                    label="Cor do Texto"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['dropdown-city-color']
                                                            : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Botão Comprar (Card do Produto)
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="btn-buy-bg"
                                                    label="Cor de Fundo"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['btn-buy-bg']
                                                            : '#27DFB3'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="btn-buy-color"
                                                    label="Cor do Texto"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['btn-buy-color']
                                                            : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="btn-buy-icon"
                                                    label="Cor do Icone"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['btn-buy-icon']
                                                            : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>

                                            <Flex
                                                width="100%"
                                                justifyContent="flex-start"
                                                alignItems="center"
                                            >
                                                <Text mr="16px" fontSize="16px" color="purple.500">
                                                    Botão Ver Mais (Lista de Produtos)
                                                </Text>
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="btn-more-bg"
                                                    label="Cor de Fundo"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['btn-more-bg']
                                                            : '#27DFB3'
                                                    }
                                                />
                                            </Flex>

                                            <Flex width="48%">
                                                <Input
                                                    name="btn-more-color"
                                                    label="Cor do Texto"
                                                    size="sm"
                                                    typeInput="color"
                                                    defaultValue={
                                                        siteSetting
                                                            ? siteSetting['btn-more-color']
                                                            : '#FFFFFF'
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%">
                                        <Flex width="100%" mt="24px">
                                            <Flex width="100%">
                                                <Button
                                                    type="submit"
                                                    width="100%"
                                                    backgroundColor="purple.500"
                                                    height="48px"
                                                    mt="24px"
                                                    color="white"
                                                    _hover={{
                                                        backgroundColor: 'green.500'
                                                    }}
                                                    fontSize="24px"
                                                    fontWeight="400"
                                                    borderRadius="2px"
                                                >
                                                    Confirmar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Divider my="0px" />
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
        )
    );
};

export default SiteSettingsPage;
