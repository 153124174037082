import React from 'react';
import QRCode from 'react-qr-code';

import { Box, Divider, Flex, Image, Stack, Text } from '@chakra-ui/core';

import logoColorful from '../../../../assets/logo-colorful.png';
import { useAuth } from '../../../../shared/hooks/useAuth';
import DateUtils from '../../../../shared/utils/DateUtils';
import TextUtils from '../../../../shared/utils/TextUtils';

interface IProps {
    order: any;
}

const OrderPrint: React.FC<IProps> = ({ order }) => {
    const { store } = useAuth();

    return (
        <Flex width="100%" height="100%" backgroundColor="white" flexDirection="column">
            <Flex flexWrap="wrap" border="2px dashed" borderColor="gray.700" p="8px">
                <Flex width="100%" justifyContent="space-between">
                    <Flex width="50%" flexDirection="column" fontSize="14px">
                        <Text as="h1" color="blue.700" fontSize="14px" mb="8px">
                            {`Pedido - ${order.code}`}
                        </Text>
                        <Text />
                        <Text>{`Forma de Pagamento: ${order.payment_method.name}`}</Text>
                        <Text>
                            {`Status de Pagamento: ${TextUtils.convertStatusPayment(
                                order.payment_status
                            )}`}
                        </Text>
                        {order.florist && (
                            <Text>{`Florista: ${order.florist.name || order.florist.email}`}</Text>
                        )}
                        {order.seller && (
                            <Text>{`Vendedor: ${order.seller.name || order.seller.email}`}</Text>
                        )}
                        {order.deliveryman && (
                            <Text>{`Entregador: ${order.deliveryman.name}`}</Text>
                        )}

                        <Flex width="100%" flexDirection="column" fontSize="14px" mt="16px">
                            <Text color="blue.700" fontSize="14px" mb="4px">
                                Dados de Entrega
                            </Text>

                            <Flex>
                                <Text mr="8px">Data de Entrega: </Text>
                                <Text>{DateUtils.format(order.delivery_date)}</Text>
                            </Flex>

                            <Flex>
                                <Text mr="8px">Hora: </Text>
                                <Text>{order.delivery_hour}</Text>
                            </Flex>

                            <Flex flexDirection={'column'}>
                                <Text>{`Cidade: ${
                                    order?.delivery_address?.city || 'Não informado'
                                }`}</Text>
                                <Text>{`Bairro: ${
                                    order?.delivery_address?.neighborhood || 'Não informado'
                                }`}</Text>
                                <Text>{`Rua: ${
                                    order?.delivery_address?.street || 'Não informado'
                                }, ${order?.delivery_address?.number || ''}`}</Text>
                                <Text>
                                    {`Complemento: ${
                                        order?.delivery_address?.complement
                                            ? order.delivery_address.complement
                                            : 'Não informado'
                                    }`}
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    <Flex width="50%" mt="16px" flexDirection="column">
                        <Flex width="100%" flexDirection="column" fontSize="14px">
                            <Text color="blue.700" fontSize="14px" mb="4px">
                                Comprador
                            </Text>
                            <Text>{`Nome: ${order.buyer.name}`}</Text>
                            <Text>{`CPF: ${TextUtils.maskCPF(order?.buyer?.document || '')}`}</Text>
                            <Text>
                                {`Telefone: ${TextUtils.mask(
                                    order.buyer.telephone,
                                    '+#############'
                                )}`}
                                <Text>{`E-mail: ${order.buyer.email}`}</Text>
                            </Text>
                        </Flex>

                        <Text whiteSpace="pre-wrap" mt="16px">
                            {`Observações: ${
                                order.details.observations
                                    ? order.details.observations
                                    : 'Não Informado'
                            }`}
                        </Text>
                    </Flex>
                </Flex>

                <Flex width="100%">
                    <Flex width="100%" mt="16px" flexDirection="column">
                        <Flex width="100%" flexDirection="column" fontSize="14px">
                            <Flex width="100%" flexDirection="column">
                                <Flex width="100%" justifyContent="space-between" fontSize="14px">
                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        maxWidth="40px"
                                        mr="40px"
                                    >
                                        <Text>Foto</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="80px">
                                        <Text>Cód.</Text>
                                    </Flex>
                                    <Flex width="100%" justifyContent="center">
                                        <Text>Nome</Text>
                                    </Flex>
                                    <Flex width="100%" justifyContent="center" maxWidth="80px">
                                        <Text>QTD</Text>
                                    </Flex>
                                </Flex>
                                <Divider width="100%" my="4px" />

                                {order.products.map((product) => (
                                    <Flex key={product.id} width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="space-between"
                                            fontSize="12px"
                                            alignItems="center"
                                        >
                                            <Flex
                                                width="100%"
                                                justifyContent="center"
                                                maxWidth="40px"
                                                mr="40px"
                                            >
                                                <img
                                                    src={
                                                        product?.variant
                                                            ? product.variant.image_url
                                                            : product.cover.thumb_url
                                                    }
                                                />
                                            </Flex>
                                            <Flex
                                                width="100%"
                                                justifyContent="center"
                                                maxWidth="80px"
                                            >
                                                <Text>{product.code_sku}</Text>
                                            </Flex>
                                            <Flex
                                                width="100%"
                                                flexDirection="column"
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Text>{product.name}</Text>
                                                {product?.variant && (
                                                    <Text>{product.variant.name}</Text>
                                                )}
                                            </Flex>
                                            <Flex
                                                width="100%"
                                                justifyContent="center"
                                                maxWidth="80px"
                                            >
                                                <Text>{product.quantity}</Text>
                                            </Flex>
                                        </Flex>

                                        <Divider width="100%" my="2px" />
                                    </Flex>
                                ))}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <Flex
                flexWrap="wrap"
                border="2px dashed"
                borderTop="none"
                borderColor="gray.700"
                p="8px"
            >
                <Flex width="100%">
                    <Flex width="100%" flexDirection="column" fontSize="14px">
                        <Text as="h1" color="blue.700" fontSize="14px" mb="8px">
                            {`Pedido - ${order.code}`}
                        </Text>

                        <Text color="blue.700" fontSize="14px" mb="8px">
                            Dados de Entrega
                        </Text>

                        <Flex flexDirection={'column'}>
                            <Text>{`Cidade: ${
                                order?.delivery_address?.city || 'Não informado'
                            }`}</Text>
                            <Text>{`Bairro: ${
                                order?.delivery_address?.neighborhood || 'Não informado'
                            }`}</Text>
                            <Text>{`Rua: ${order?.delivery_address?.street || 'Não informado'}, ${
                                order?.delivery_address?.number || ''
                            }`}</Text>
                            <Text>
                                {`Complemento: ${
                                    order?.delivery_address?.complement
                                        ? order.delivery_address.complement
                                        : 'Não informado'
                                }`}
                            </Text>
                        </Flex>

                        <Flex>
                            <Text mr="8px">Data de Entrega: </Text>
                            <Text>{DateUtils.format(order.delivery_date)}</Text>
                        </Flex>

                        <Flex>
                            <Text mr="8px">Hora: </Text>
                            <Text>{order.delivery_hour}</Text>
                        </Flex>

                        <Text color="black" fontSize="14px" mt="24px" whiteSpace="pre-wrap">
                            {`Obs: ${order.details.observations || 'Não informada'}`}
                        </Text>
                    </Flex>

                    <Flex width="50%" flexDirection="column" fontSize="14px">
                        <Text color="blue.700" fontSize="14px" mb="8px">
                            Dados do Destinatário
                        </Text>
                        <Text>{`Nome: ${order.details.destinatary_name}`}</Text>
                        <Text>
                            {`Telefone: ${
                                order.details.destinatary_telephone
                                    ? TextUtils.mask(
                                          order.details.destinatary_telephone,
                                          '+#############'
                                      )
                                    : 'Não informado'
                            }`}
                        </Text>

                        <Flex mt="24px">
                            <QRCode
                                size={80}
                                style={{ height: 'auto', maxWidth: '80%', width: '80%' }}
                                value={`https://dashboard.eflorista.com.br/delivery/${store}/${order.id}`}
                                viewBox={'0 0 100 100'}
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <Divider width="100%" my="16px" />

                <Flex width="100%" flexDirection="column">
                    <Flex width="100%" justifyContent="space-between" fontSize="14px">
                        <Flex width="100%" justifyContent="center" maxWidth="40px" mr="40px">
                            <Text>Foto</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="80px">
                            <Text>Cód.</Text>
                        </Flex>
                        <Flex width="100%" justifyContent="center">
                            <Text>Nome</Text>
                        </Flex>
                        <Flex width="100%" justifyContent="center" maxWidth="80px">
                            <Text>QTD</Text>
                        </Flex>
                    </Flex>
                    <Divider width="100%" my="4px" />

                    {order.products.map((product) => (
                        <Flex key={product.id} width="100%" flexDirection="column">
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                fontSize="12px"
                                alignItems="center"
                            >
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth="40px"
                                    mr="40px"
                                >
                                    <img
                                        src={
                                            product?.variant
                                                ? product.variant.image_url
                                                : product.cover.thumb_url
                                        }
                                    />
                                </Flex>
                                <Flex width="100%" justifyContent="center" maxWidth="80px">
                                    <Text>{product.code_sku}</Text>
                                </Flex>
                                <Flex
                                    width="100%"
                                    flexDirection="column"
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Text>{product.name}</Text>
                                    {product?.variant && <Text>{product.variant.name}</Text>}
                                </Flex>
                                <Flex width="100%" justifyContent="center" maxWidth="80px">
                                    <Text>{product.quantity}</Text>
                                </Flex>
                            </Flex>

                            <Divider width="100%" my="2px" />
                        </Flex>
                    ))}
                </Flex>

                <Flex width="100%" mt="24px">
                    <Flex width="100%" flexDirection="column">
                        <Stack direction="row" spacing="8">
                            <Flex fontSize="14px">
                                <Text mr="8px">De:</Text>
                                <Text>{order.details.identify_name || 'Não Identificado'}</Text>
                            </Flex>

                            <Flex fontSize="14px">
                                <Text mr="8px">Para:</Text>
                                <Text>{order.details.destinatary_name}</Text>
                            </Flex>
                        </Stack>
                        <Text color="blue.700" fontSize="14px" mt="8px">
                            Mensagem a ser impressa no cartão
                        </Text>
                        <Text fontSize="12px" whiteSpace="pre-wrap" mb="16px">
                            {order.details.card_message
                                ? order.details.card_message
                                : 'Não informada'}
                        </Text>
                    </Flex>
                </Flex>

                <Flex width="100%" fontSize="14px">
                    <Flex alignItems="center" mr="24px">
                        <Flex
                            width="14px"
                            height="14px"
                            border="1px solid"
                            borderColor="black"
                            mr="8px"
                        ></Flex>
                        <Text>Entregue em mãos</Text>
                    </Flex>
                    <Flex alignItems="center">
                        <Text>Nome de quem recebeu: _________________________________</Text>
                    </Flex>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Flex width="30%" height="100%" justifyContent="center" alignItems="center">
                        <Box width="100%">
                            <Image src={logoColorful} opacity={0.2} />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default OrderPrint;
