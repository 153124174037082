import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Checkbox, Divider, Flex, PseudoBox, Text } from '@chakra-ui/core';

import Button from '../../../../components/Button';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const ServicesPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [selectedServicesIds, serviceselectedServicesIds] = useState([]);
    const [services, serviceservices] = useState([]);

    const handleDeleteServices = useCallback(() => {
        apiGateway
            .delete('/settings/set-services', {
                params: {
                    ids: selectedServicesIds
                }
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    navigate(0);
                }
            });
    }, [selectedServicesIds]);

    const handleChangeSelectedServicesIds = useCallback(
        (input: any) => {
            let list = selectedServicesIds;

            if (input.checked) {
                if (!selectedServicesIds.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter(item => item !== input.value);
            }

            serviceselectedServicesIds(list);
        },
        [selectedServicesIds]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate(`/settings/services/edit/${id}`);
        },
        [navigate]
    );

    const SetRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={e =>
                            handleChangeSelectedServicesIds(e.currentTarget)
                        }
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].link}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        title="Visualizar"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEdit(data[index].id)}
                    >
                        <FiEdit2 size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Serviços');
        apiGateway.get('/settings/set-services').then(response => {
            const { status } = response;
            if (status === HTTP.STATUS.SUCCESS) serviceservices(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/settings/services/register')}
                >
                    Novo Serviço
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Serviços"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteServices()}
                >
                    <FiTrash2 size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex
                            width="50%"
                            justifyContent="flex-start"
                            alignItems="center"
                        >
                            <Text mr="16px" fontSize="24px" color="purple.500">
                                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex
                            width="100%"
                            justifyContent="center"
                            maxWidth="40px"
                        >
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Serviço</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center">
                            <Text>Link</Text>
                        </Flex>

                        <Flex
                            width="100%"
                            justifyContent="center"
                            maxWidth="104px"
                        >
                            <Text>Ação</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {services.length > 0 && (
                        <List item={services} row={SetRow} itemHeight={48} />
                    )}

                    {services.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default ServicesPage;
