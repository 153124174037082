import { format, parseISO, subMonths } from 'date-fns';
import React, { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BiCheckDouble, BiHide } from 'react-icons/bi';
import { FiCalendar, FiEdit2, FiEye, FiFile, FiPrinter, FiSearch } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import {
    Box,
    Checkbox,
    Collapse,
    Divider,
    Flex,
    Icon,
    PseudoBox,
    Text,
    useDisclosure
} from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import { DayModifiers } from 'react-day-picker';
import { MdOutlineEmail } from 'react-icons/md';
import Button from '../../../../components/Button';
import DeliveryStatus from '../../../../components/DeliveryStatus';
import Form from '../../../../components/Form';
import Calendar from '../../../../components/Form/Calendar';
import Input from '../../../../components/Form/Input';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import PanelButton from '../../../../components/PanelButton';
import PaymentStatus from '../../../../components/PaymentStatus';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import ModalConfirmation from '../../components/ModalConfirmation';
import OrderPrint from '../../components/OrderPrint';

const CHANNEL_LEGEND = {
    ECOMMERCE: 'pink.300',
    MARKETPLACE: 'green.500',
    CASHIER: 'blue.500'
};

const OrdersListPage: React.FC = () => {
    const { changeTitle } = useLayout();
    const [orders, setOrders] = useState([]);
    const formRef = useRef<FormHandles>(null);
    const [selectedOrdersIds, setSelectedOrdersIds] = useState([]);
    const [listOrdersType, setListOrdersType] = useState('ALL');
    const { isOpen, onOpen, onClose } = useDisclosure();
    const navigate = useNavigate();
    const [printRefs, setPrintRefs] = useState<React.MutableRefObject<any>[]>([]);

    const [showCalendar, setShowCalendar] = useState(false);
    const [periodDate1Filter, setPeriodDate1Filter] = useState(subMonths(new Date(), 3));
    const [periodDate2Filter, setPeriodDate2Filter] = useState(new Date());

    const [currentChannel, setCurrentChannel] = useState<keyof typeof CHANNEL_LEGEND | ''>('');

    const [orderToPrint, setOrderToPrint] = useState(null);

    const totalByChannel = useMemo(() => {
        return {
            ECOMMERCE: orders
                .filter((item) => item.channel_type === 'ECOMMERCE')
                .reduce((prev, curr) => {
                    return prev + Number(curr.total_value);
                }, 0),
            MARKETPLACE: orders
                .filter((item) => item.channel_type === 'MARKETPLACE')
                .reduce((prev, curr) => {
                    return prev + Number(curr.total_value);
                }, 0),
            CASHIER: orders
                .filter((item) => item.channel_type === 'CASHIER')
                .reduce((prev, curr) => {
                    return prev + Number(curr.total_value);
                }, 0)
        };
    }, [orders]);

    const loadData = useCallback(() => {
        apiGateway
            .get(
                `/orders?status=${listOrdersType}&start_date=${format(
                    periodDate1Filter,
                    'yyyy-MM-dd'
                )}&end_date=${format(periodDate2Filter, 'yyyy-MM-dd')}`
            )
            .then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setOrders(response.data);

                    const refs = [];

                    response.data.map((order) => {
                        refs[order.id] = createRef();
                        return refs;
                    });

                    setPrintRefs(refs);
                }
            });
    }, [listOrdersType, periodDate1Filter, periodDate2Filter]);

    const handleChangePeriodDate1Filter = useCallback(
        (date: Date, modifiers: DayModifiers) => {
            if (modifiers.available && !modifiers.disabled) {
                setPeriodDate1Filter(date);

                apiGateway
                    .get(
                        `/orders?channel=${currentChannel}&start_date=${format(
                            date,
                            'yyyy-MM-dd'
                        )}&end_date=${format(periodDate2Filter, 'yyyy-MM-dd')}`
                    )
                    .then((response) => {
                        if (response.status === HTTP.STATUS.SUCCESS) {
                            setOrders(response.data);

                            const refs = [];

                            response.data.map((order) => {
                                refs[order.id] = createRef();
                                return refs;
                            });

                            setPrintRefs(refs);
                        }
                    });
            }
        },
        [currentChannel, periodDate2Filter]
    );

    const handleChangePeriodDate2Filter = useCallback(
        (date: Date, modifiers: DayModifiers) => {
            if (modifiers.available && !modifiers.disabled) {
                setPeriodDate2Filter(date);

                apiGateway
                    .get(
                        `/orders?channel=${currentChannel}&start_date=${format(
                            periodDate1Filter,
                            'yyyy-MM-dd'
                        )}&end_date=${format(date, 'yyyy-MM-dd')}`
                    )
                    .then((response) => {
                        if (response.status === HTTP.STATUS.SUCCESS) {
                            setOrders(response.data);

                            const refs = [];

                            response.data.map((order) => {
                                refs[order.id] = createRef();
                                return refs;
                            });

                            setPrintRefs(refs);
                        }
                    });
            }
        },
        [currentChannel, periodDate1Filter]
    );

    const handleChangeChannel = useCallback(
        (channel: keyof typeof CHANNEL_LEGEND) => {
            if (channel) {
                let currentValue = channel;

                setCurrentChannel((oldState) => {
                    if (oldState === channel) {
                        currentValue = null;
                        return undefined;
                    }

                    return channel;
                });

                if (!currentValue) {
                    loadData();
                } else {
                    apiGateway
                        .get(
                            `/orders?channel=${channel}&status=${listOrdersType}&start_date=${format(
                                periodDate1Filter,
                                'yyyy-MM-dd'
                            )}&end_date=${format(periodDate2Filter, 'yyyy-MM-dd')}`
                        )
                        .then((response) => {
                            if (response.status === HTTP.STATUS.SUCCESS) {
                                setOrders(response.data);

                                const refs = [];

                                response.data.map((order) => {
                                    refs[order.id] = createRef();
                                    return refs;
                                });

                                setPrintRefs(refs);
                            }
                        });
                }
            }
        },
        [listOrdersType, periodDate1Filter, periodDate2Filter]
    );

    const handleChangeOrdersList = useCallback(
        (type) => {
            apiGateway
                .get(
                    `/orders?channel=${currentChannel}&status=${type}&start_date=${format(
                        periodDate1Filter,
                        'yyyy-MM-dd'
                    )}&end_date=${format(periodDate2Filter, 'yyyy-MM-dd')}`
                )
                .then((response) => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        setOrders(response.data);
                    }
                });

            setListOrdersType(type);
        },
        [currentChannel, periodDate1Filter, periodDate2Filter]
    );

    const handleViewOrderDetails = useCallback(
        (code) => {
            navigate(`/orders/${code}`);
        },
        [navigate]
    );

    const handleChangePaymentStatus = useCallback(async (orderID: string, status: string) => {
        const response = await apiGateway.put(`/orders/${orderID}/payment_status`, {
            status
        });

        if (response.status === HTTP.STATUS.SUCCESS) {
            setOrders((oldState) => {
                return [
                    ...oldState.map((item) => {
                        if (item.id === orderID) {
                            return {
                                ...item,
                                payment_status: status
                            };
                        }

                        return item;
                    })
                ];
            });
        }
    }, []);

    const handleChangeDeliveryStatus = useCallback(async (orderID: string, status: string) => {
        const response = await apiGateway.put(`/orders/${orderID}/delivery_status`, {
            status
        });

        if (response.status === HTTP.STATUS.SUCCESS) {
            setOrders((oldState) => {
                return [
                    ...oldState.map((item) => {
                        if (item.id === orderID) {
                            return {
                                ...item,
                                delivery_status: status
                            };
                        }

                        return item;
                    })
                ];
            });
        }
    }, []);

    const handleHideOrdersSelected = useCallback(async () => {
        await apiGateway
            .put('/orders/hide', {
                ids: selectedOrdersIds,
                is_hidden: true
            })
            .then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    const updatedListOrders = orders.filter(
                        (product) => !selectedOrdersIds.includes(product.id)
                    );

                    setOrders(updatedListOrders);
                    setSelectedOrdersIds([]);
                }
            });
        onClose();
    }, [onClose, orders, selectedOrdersIds]);

    const handleChangePrintStatus = useCallback(async (id: string) => {
        await apiGateway.put(`/orders/${id}/print_status`).then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrders((oldState) => {
                    const updateState = [...oldState];
                    return updateState.map((order) => {
                        return {
                            ...order,
                            was_printed: order.id === id ? true : order.was_printed
                        };
                    });
                });
            }
        });
    }, []);

    const handleSearch = useCallback(() => {
        const query = formRef.current.getFieldValue('query');

        if (!!query !== false && query !== '') {
            apiGateway.get(`/orders?query=${query}`).then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setOrders(response.data);

                    const refs = [];

                    response.data.map((order) => {
                        refs[order.code] = createRef();
                        return refs;
                    });

                    setPrintRefs(refs);
                }
            });
        } else {
            loadData();
        }
    }, [loadData]);

    const handleChangeSelectedOrdersIds = useCallback((value: string) => {
        setSelectedOrdersIds((oldState) => {
            let list = oldState;

            if (!oldState.includes(value)) {
                list.push(value);
            } else {
                list = list.filter((item) => item !== value);
            }

            return [...list];
        });
    }, []);

    const promiseResolve: any = useRef();

    const onBeforeGetContent = (id: string) => {
        return new Promise((resolve) => {
            apiGateway.get(`/orders/${id}`).then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setOrderToPrint(response.data);
                    promiseResolve.current = resolve;
                }
            });
        });
    };

    useEffect(() => {
        if (orderToPrint) {
            promiseResolve.current();
        }
    }, [orderToPrint]);

    const OrderRow = ({ style, index, data }: any) => {
        return (
            <ListRow style={style} index={index}>
                <Flex
                    position="relative"
                    flexDirection="column"
                    pl="4px"
                    height="100%"
                    justifyContent="space-between"
                >
                    <Box
                        width="14px"
                        height="14px"
                        backgroundColor={CHANNEL_LEGEND[data[index].channel_type]}
                        zIndex={10000}
                    />
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onClick={() => handleChangeSelectedOrdersIds(data[index].id)}
                        value={data[index].id}
                        borderColor="purple.500"
                        isChecked={selectedOrdersIds.includes(data[index].id)}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="96px">
                    <Text>{data[index].code}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text textTransform="capitalize" wordBreak="break-all">
                        {String(data[index]?.buyer?.name || '').toLowerCase()}
                    </Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{CurrencyUtils.numberToCurrency(data[index].total_value)}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="184px" px="4px">
                    <PaymentStatus
                        orderId={data[index].id}
                        defaultStatus={data[index].payment_status}
                        onChangeFunction={handleChangePaymentStatus}
                        width="100%"
                        isChangeable
                    />
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="184px" px="4px">
                    {data[index].payment_status !== 'NEGADO' && (
                        <DeliveryStatus
                            defaultStatus={data[index].delivery_status}
                            orderId={data[index].id}
                            onChangeFunction={handleChangeDeliveryStatus}
                            width="100%"
                            isChangeable={data[index].delivery_status !== 'ENTREGUE'}
                        />
                    )}
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{data[index].payment_method.name}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center">
                    <Text>{format(parseISO(data[index].created_at), 'dd/MM/yy - HH:mm')}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px" alignItems="center">
                    <PseudoBox
                        title="Visualizar"
                        p="4px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        onClick={() => handleViewOrderDetails(data[index].id)}
                        cursor="pointer"
                        mr="4px"
                    >
                        <FiEye size={14} />
                    </PseudoBox>

                    <PseudoBox
                        title="Editar Produto"
                        p="4px"
                        mr="4px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => navigate(`/orders/edit/${data[index].id}`)}
                    >
                        <FiEdit2 size={14} />
                    </PseudoBox>

                    {(data[index].payment_method.name === 'Depósito' ||
                        data[index].payment_method.name === 'Depósito/Pix') && (
                        <PseudoBox
                            title="VER COMPROVANTE"
                            p="4px"
                            mr="4px"
                            backgroundColor={
                                !!data[index].url_voucher === true ? 'green.500' : 'red.500'
                            }
                            color="white"
                            borderRadius="2px"
                            cursor="pointer"
                            onClick={() => navigate(`/orders/${data[index].id}/voucher`)}
                        >
                            <FiFile size={14} />
                        </PseudoBox>
                    )}

                    <PseudoBox
                        title="VER CARTÃO"
                        mr="4px"
                        p="3px"
                        backgroundColor="blue.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => navigate(`/orders/${data[index].id}/card/edit`)}
                        position="relative"
                    >
                        <MdOutlineEmail size={16} />

                        {data[index].is_card_printed && (
                            <PseudoBox
                                position="absolute"
                                bottom="-8px"
                                right="-8px"
                                color="white"
                                backgroundColor="green.500"
                                p="2px"
                                borderRadius="2px"
                                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                                zIndex={1000}
                            >
                                <BiCheckDouble />
                            </PseudoBox>
                        )}
                    </PseudoBox>

                    <ReactToPrint
                        content={() => printRefs[data[index].id].current}
                        pageStyle={`
                    *, h1, h2, h3, h4, h5, p, a {
                      color: black!important;
                    `}
                        onBeforeGetContent={() => onBeforeGetContent(data[index].id)}
                        onBeforePrint={() => {
                            handleChangePrintStatus(data[index].id);
                        }}
                    >
                        <PrintContextConsumer>
                            {({ handlePrint }) => (
                                <PseudoBox
                                    position="relative"
                                    title="Imprimir"
                                    p="6px"
                                    backgroundColor={data[index].was_printed ? 'white' : 'white'}
                                    color="purple.500"
                                    border="2px solid"
                                    borderColor="purple.500"
                                    borderRadius="2px"
                                    cursor="pointer"
                                    onClick={() => {
                                        handlePrint();
                                    }}
                                >
                                    <FiPrinter />

                                    {data[index].was_printed && (
                                        <PseudoBox
                                            position="absolute"
                                            bottom="-8px"
                                            right="-8px"
                                            color="white"
                                            backgroundColor="green.500"
                                            p="2px"
                                            borderRadius="2px"
                                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                                        >
                                            <BiCheckDouble />
                                        </PseudoBox>
                                    )}
                                </PseudoBox>
                            )}
                        </PrintContextConsumer>
                    </ReactToPrint>
                    <Flex display="none">
                        <Flex ref={printRefs[data[index].id]}>
                            {orderToPrint && (
                                <OrderPrint key={orderToPrint.id} order={orderToPrint} />
                            )}
                        </Flex>
                    </Flex>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Pedidos');
        loadData();
    }, [changeTitle, loadData]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" flexDirection="column" backgroundColor="white" mt="24px" px="24px">
                <Flex width="100%" py="8px" justifyContent="space-between" flexWrap="nowrap">
                    <Form
                        ref={formRef as any}
                        onSubmit={() => null}
                        style={{
                            display: 'flex'
                        }}
                    >
                        <Flex flexDirection="column">
                            <Flex mb="8px" style={{ gap: '8px' }}>
                                <PseudoBox
                                    alignItems="center"
                                    style={{ gap: '8px' }}
                                    display="flex"
                                    _hover={{
                                        borderColor: 'blue.500'
                                    }}
                                    border={'2px'}
                                    borderColor={
                                        currentChannel === 'ECOMMERCE' ? 'blue.500' : 'gray.500'
                                    }
                                    px="8px"
                                    py="4px"
                                    cursor="pointer"
                                    onClick={() => handleChangeChannel('ECOMMERCE')}
                                >
                                    <Box
                                        width="12px"
                                        height="12px"
                                        mr="8px"
                                        backgroundColor={CHANNEL_LEGEND.ECOMMERCE}
                                    />
                                    <Text fontSize="12px">ECOMMERCE</Text>
                                    <Text fontSize="16px" color="gray.700">
                                        {CurrencyUtils.numberToCurrency(totalByChannel.ECOMMERCE)}
                                    </Text>
                                </PseudoBox>

                                <PseudoBox
                                    alignItems="center"
                                    style={{ gap: '8px' }}
                                    display="flex"
                                    _hover={{
                                        borderColor: 'blue.500'
                                    }}
                                    border={'2px'}
                                    borderColor={
                                        currentChannel === 'MARKETPLACE' ? 'blue.500' : 'gray.500'
                                    }
                                    px="8px"
                                    py="4px"
                                    cursor="pointer"
                                    onClick={() => handleChangeChannel('MARKETPLACE')}
                                >
                                    <Box
                                        width="12px"
                                        height="12px"
                                        mr="8px"
                                        backgroundColor={CHANNEL_LEGEND.MARKETPLACE}
                                    />
                                    <Text fontSize="12px">MARKETPLACE</Text>
                                    <Text fontSize="16px" color="gray.700">
                                        {CurrencyUtils.numberToCurrency(totalByChannel.MARKETPLACE)}
                                    </Text>
                                </PseudoBox>

                                <PseudoBox
                                    alignItems="center"
                                    style={{ gap: '8px' }}
                                    display="flex"
                                    _hover={{
                                        borderColor: 'blue.500'
                                    }}
                                    border={'2px'}
                                    borderColor={
                                        currentChannel === 'CASHIER' ? 'blue.500' : 'gray.500'
                                    }
                                    px="8px"
                                    py="4px"
                                    cursor="pointer"
                                    onClick={() => handleChangeChannel('CASHIER')}
                                >
                                    <Box
                                        width="12px"
                                        height="12px"
                                        backgroundColor={CHANNEL_LEGEND.CASHIER}
                                    />
                                    <Text fontSize="12px">LOJA FÍSICA</Text>
                                    <Text fontSize="16px" color="gray.700">
                                        {CurrencyUtils.numberToCurrency(totalByChannel.CASHIER)}
                                    </Text>
                                </PseudoBox>
                            </Flex>

                            <Flex alignItems="center">
                                <Text
                                    mr="16px"
                                    fontSize="18px"
                                    color="purple.500"
                                    whiteSpace="nowrap"
                                >
                                    Lista de Vendas
                                </Text>

                                <Text
                                    mr="16px"
                                    fontSize="14px"
                                    color="gray.700"
                                    whiteSpace="nowrap"
                                >
                                    {`${orders.length} itens`}
                                </Text>

                                <Input
                                    width="200px"
                                    name="query"
                                    placeholder="Pesquisar por código"
                                    size="sm"
                                    mb="0px"
                                    onEnterPress={() => handleSearch()}
                                />

                                <Button
                                    height="32px"
                                    width="40px"
                                    borderRadius="2px"
                                    ml="8px"
                                    backgroundColor="green.500"
                                    cursor="pointer"
                                    onClick={() => handleSearch()}
                                >
                                    <Icon as={FiSearch} size="20px" />
                                </Button>

                                <Button
                                    height="32px"
                                    width="40px"
                                    borderRadius="2px"
                                    border="2px solid"
                                    borderColor="gray.500"
                                    ml="8px"
                                    backgroundColor="white.500"
                                    cursor="pointer"
                                    onClick={() => onOpen()}
                                    _hover={{
                                        backgroundColor: 'white'
                                    }}
                                    title="Ocultar Vendas"
                                >
                                    <Icon as={BiHide} size="20px" color="gray.600" />
                                </Button>
                            </Flex>
                        </Flex>
                    </Form>

                    <Flex flexDirection="column" style={{ gap: '8px' }} mt="8px">
                        <Flex>
                            <Text fontSize="12px" mr="16px">
                                Período
                            </Text>
                            <Flex flexDirection="column" mr="16px">
                                <Button
                                    width="144px"
                                    backgroundColor="purple.500"
                                    borderRadius="2px"
                                    size="sm"
                                    height="28px"
                                    py="0px"
                                    fontSize="12px"
                                    display="flex"
                                    alignItems="center"
                                    _focus={{
                                        outline: 'none'
                                    }}
                                    _hover={{
                                        backgroundColor: 'purple.500'
                                    }}
                                    onClick={() => setShowCalendar((oldState) => !oldState)}
                                >
                                    <FiCalendar size={16} />
                                    <Text ml="8px">
                                        {`De ${format(periodDate1Filter, 'dd/MM/yyyy')}`}
                                    </Text>
                                </Button>

                                <Collapse width="100%" isOpen={showCalendar} display="flex">
                                    <Calendar
                                        isErrored={false}
                                        selectedDate={periodDate1Filter}
                                        handleDateChange={handleChangePeriodDate1Filter}
                                        handleMonthChange={undefined}
                                    />
                                </Collapse>
                            </Flex>

                            <Flex flexDirection="column">
                                <Button
                                    width="144px"
                                    backgroundColor="purple.500"
                                    borderRadius="2px"
                                    size="sm"
                                    height="28px"
                                    py="0px"
                                    fontSize="12px"
                                    display="flex"
                                    alignItems="center"
                                    _focus={{
                                        outline: 'none'
                                    }}
                                    _hover={{
                                        backgroundColor: 'purple.500'
                                    }}
                                    onClick={() => setShowCalendar((oldState) => !oldState)}
                                >
                                    <FiCalendar size={16} />
                                    <Text ml="8px">
                                        {`Até ${format(periodDate2Filter, 'dd/MM/yyyy')}`}
                                    </Text>
                                </Button>

                                <Collapse width="100%" isOpen={showCalendar} display="flex">
                                    <Calendar
                                        isErrored={false}
                                        selectedDate={periodDate2Filter}
                                        handleDateChange={handleChangePeriodDate2Filter}
                                        handleMonthChange={undefined}
                                    />
                                </Collapse>
                            </Flex>
                        </Flex>
                        <Flex alignItems="center">
                            <Text fontSize="12px" mr="13px">
                                Mostrar:
                            </Text>

                            <PanelButton
                                text="Todas"
                                isSelected={listOrdersType === 'ALL'}
                                buttonFunction={() => handleChangeOrdersList('ALL')}
                                minWidth="80px"
                            />

                            <PanelButton
                                text="Aprovadas"
                                isSelected={listOrdersType === 'APPROVED'}
                                buttonFunction={() => handleChangeOrdersList('APPROVED')}
                            />

                            <PanelButton
                                text="Pendentes"
                                isSelected={listOrdersType === 'PENDENT'}
                                buttonFunction={() => handleChangeOrdersList('PENDENT')}
                            />

                            <PanelButton
                                text="Negadas"
                                isSelected={listOrdersType === 'DENIED'}
                                buttonFunction={() => handleChangeOrdersList('DENIED')}
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <Divider my="0px" />
                <Flex
                    width="100%"
                    py="8px"
                    justifyContent="space-between"
                    fontSize="12px"
                    maxWidth="100%"
                    textTransform="uppercase"
                >
                    <Flex width="100%" justifyContent="center" maxWidth="96px">
                        <Text>Pedido</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center">
                        <Text>Cliente</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center">
                        <Text>Valor</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center" maxWidth="184px">
                        <Text>Status Pagamento</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center" maxWidth="184px">
                        <Text>Status Entrega</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center">
                        <Text>Pagamento</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center">
                        <Text>Data</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center" maxWidth="144px" mr="12px">
                        <Text>Ação</Text>
                    </Flex>
                </Flex>
                <Divider my="0px" />

                {orders.length > 0 && <List item={orders} row={OrderRow} itemHeight={48} />}

                {orders.length <= 0 && <ListEmpty />}
            </Flex>
            <ModalConfirmation
                isOpen={isOpen}
                onClose={onClose}
                actionFunction={handleHideOrdersSelected}
            />
        </Flex>
    );
};

export default OrdersListPage;
