import React, {
    createContext,
    useCallback,
    useState,
    useContext,
} from 'react';

import { useToast } from '@chakra-ui/core';

import { HTTP } from '../constants';
import apiGateway from '../services/apiGateway';
import axios from 'axios';

interface IUser {
  id: string;
  name: string;
  email: string;
  avatar_url: string;
  permission?: string;
}

interface IAuthState {
  token: string | null;
  user: IUser | null;
  branch: string | null;
  store: string | null;
  store_name: string | null;
}

interface ISignInCredentials {
  email: string;
  password: string;
}

interface IAuthContextData {
  user: IUser | null;
  branch: string | null;
  store: string | null;
  store_name: string | null;
  setSession: any,
  signIn(credentials: ISignInCredentials): Promise<boolean>;
  signOut(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC<any> = ({ children }) => {
    const [session, setSession] = useState<IAuthState>(() => {
        return {
            token: null,
            user: null,
            branch: null,
            store: null,
            store_name: null
        };
    });

    const toast = useToast();

    const signOut = useCallback(() => {
        localStorage.removeItem('@eflorista:token');
        localStorage.removeItem('@eflorista:user');
        localStorage.removeItem('@eflorista:branch');
        localStorage.removeItem('@eflorista:store');
        localStorage.removeItem('@eflorista:store_name');

        setSession({} as IAuthState);
    }, []);

    const signIn = useCallback(
        async ({ email, password }) => {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {
                email, password
            });

            if (response.status === HTTP.STATUS.SUCCESS) {
                const { token, user, branch, store, store_name } = response.data;

                localStorage.setItem('@eflorista:token', token);
                localStorage.setItem('@eflorista:user', JSON.stringify(user));
                localStorage.setItem('@eflorista:branch', branch);
                localStorage.setItem('@eflorista:store', store);
                localStorage.setItem('@eflorista:store_name', store_name);

                apiGateway.defaults.headers.authorization = `Bearer ${token}`;

                setSession({ token, user, branch, store, store_name });

                return true;
            } else {
                return false;
            }
        },
        [toast]
    );

    return (
        <AuthContext.Provider
            value={{
                user: session.user,
                branch: session.branch,
                store: session.store,
                store_name: session.store_name,
                setSession,
                signIn,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): IAuthContextData {
    const context = useContext(AuthContext);
    return context;
}

export { AuthProvider, useAuth };
