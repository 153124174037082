import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Flex, InputProps, Text } from '@chakra-ui/core';
import { useField } from '@unform/core';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InputBadgeError from '../InputBadgeError';

interface IProps extends InputProps<HTMLTextAreaElement> {
    name: string;
    label?: string;
    isRequired?: boolean;
    formatChars?: {
        [key: string]: string;
    };
    badgeErrorColor?: string;
    mb?: string | number;
    onChange?: (data: any) => void;
}

const TextEditor: React.FC<IProps> = ({
    name,
    label,
    isRequired = false,
    badgeErrorColor = 'red',
    defaultValue = '',
    onChange = null,
    width = '100%'
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);
    const [value, setValue] = useState('');

    const { fieldName, error, registerField } = useField(name);

    const handleChange = useCallback((newValue) => {
        if (onChange) {
            onChange(newValue);
        }
        setValue(newValue);
    }, []);

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue as string);
        }

        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: 'value'
        });
    }, [defaultValue, fieldName, registerField]);

    return (
        <Flex
            className="no-print"
            width={width}
            flexDirection="column"
            fontWeight="500"
            mb="64px"
            zIndex={20}
        >
            {label && (
                <Flex width="100%" textAlign="center" mb="4px">
                    <Text>{label}</Text>
                    {isRequired && (
                        <Text ml="8px" color="red.500">
                            *
                        </Text>
                    )}
                </Flex>
            )}

            <Flex width="100%" flexDirection="column">
                <ReactQuill theme="snow" value={value} onChange={handleChange} />
                <textarea
                    ref={inputRef}
                    style={{ visibility: 'hidden' }}
                    name={name}
                    value={value}
                />
                {error && <InputBadgeError error={error} color={badgeErrorColor} />}
            </Flex>
        </Flex>
    );
};

export default TextEditor;
