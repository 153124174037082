import { ThemeProvider as EmotionThemeProvider } from 'emotion-theming';
import React from 'react';

import { ThemeProvider as ChakraThemeProvider, ColorModeProvider, CSSReset } from '@chakra-ui/core';

import theme from '../../styles/theme';

const ThemeContainer: React.FC<any> = ({ children }) => (
    //@ts-ignore
    <ChakraThemeProvider theme={theme}>
        <CSSReset />
        <ColorModeProvider value="light">
            <EmotionThemeProvider theme={theme}>{children}</EmotionThemeProvider>
        </ColorModeProvider>
    </ChakraThemeProvider>
);

export default ThemeContainer;
