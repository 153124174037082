import * as Yup from 'yup';

export default class ProductValidator {
    static async check(productData) {
        const schemaValidation = Yup.object().shape({
            code_sku: Yup.string().required('O código do produto não foi informado'),
            name: Yup.string().required('Nome não informado'),
            slug: Yup.string().required('Slug não informado'),
            price_sale: Yup.number()
                .min(0, 'O Produto precisa ter um valor númerico')
                .required('Preço de venda não informado'),
            description: Yup.string().required('Descrição não informado'),
            title: Yup.string().required('Título não informado'),
            meta_description: Yup.string().required('Meta description não informada'),
            cover_id: Yup.string().required('O produto não possui uma foto capa'),
            is_enable: Yup.boolean(),
            is_promotional: Yup.boolean(),
            is_free_shiping: Yup.boolean()
        });

        await schemaValidation.validate(productData, {
            abortEarly: false
        });
    }
}
