import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../../components/Form';
import Input from '../../../../../components/Form/Input';
import { useLayout } from '../../../../../layouts/default';
import { HTTP } from '../../../../../shared/constants';
import apiGateway from '../../../../../shared/services/apiGateway';

const PaypalSettings: React.FC = () => {
    const { changeTitle } = useLayout();
    const toast = useToast();
    const [depositMethod, setDepositMethod] = useState(null);
    const [dataJson, setDataJSON] = useState(null as any);
    const formRef = useRef<FormHandles>(null);
    const handleSubmit = useCallback(
        (data) => {
            apiGateway
                .put(`/settings/payment_methods/${depositMethod.id}`, {
                    ...depositMethod,
                    data: JSON.stringify({
                        client_id: data.client_id,
                        client_secret: data.client_secret
                    })
                })
                .then((response) => {
                    const { status } = response;
                    if (status === HTTP.STATUS.SUCCESS) {
                        toast({
                            title: 'Configuração Atualizada',
                            description: '',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });
                    }
                });
        },
        [depositMethod]
    );

    useEffect(() => {
        changeTitle('Configurarações de Depósito');
        apiGateway.get('/settings/payment_methods/PAYPAL').then((response) => {
            setDepositMethod(response.data);

            try {
                const dataParse = JSON.parse(response.data.data);
                setDataJSON(dataParse);
            } catch (e) {
                console.log(e);
            }
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column">
                <Flex width="100%" flexDirection="column">
                    <Flex width="100%" flexDirection="column" backgroundColor="white" px="24px">
                        <Flex width="100%" py="16px" justifyContent="space-between" flexWrap="wrap">
                            <Flex width="100%" justifyContent="space-between" flexWrap="wrap">
                                <Flex width="100%" justifyContent="flex-start" alignItems="center">
                                    <Text mr="16px" fontSize="24px" color="purple.500">
                                        Configuração Paypal
                                    </Text>
                                </Flex>

                                <Flex width="100%">
                                    <Flex width="100%" mt="24px" justifyContent={'space-between'}>
                                        <Flex width="100%" flexDirection="column">
                                            <Flex flexDirection={'column'} width="60%">
                                                <Input
                                                    label="Client ID"
                                                    name="client_id"
                                                    size="sm"
                                                    defaultValue={dataJson?.client_id}
                                                />
                                                <Input
                                                    label="Client Secret"
                                                    name="client_secret"
                                                    size="sm"
                                                    defaultValue={dataJson?.client_secret}
                                                />
                                            </Flex>

                                            <Flex width="60%">
                                                <Button
                                                    type="submit"
                                                    width="100%"
                                                    backgroundColor="purple.500"
                                                    height="48px"
                                                    mt="24px"
                                                    color="white"
                                                    _hover={{
                                                        backgroundColor: 'green.500'
                                                    }}
                                                    fontSize="24px"
                                                    fontWeight="400"
                                                    borderRadius="2px"
                                                >
                                                    Confirmar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Form>
    );
};

export default PaypalSettings;
