import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Divider, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const AnalyticsChatPage: React.FC = () => {
    const { changeTitle } = useLayout();

    const formRef = useRef<FormHandles>(null);

    const toast = useToast();

    const [integrationSettings, setIntegrationSetting] = useState(null);

    const handleSubmit = useCallback(
        async (data) => {
            formRef.current.setErrors({});

            console.log(data);

            const response = await apiGateway.put('/settings/integrations', data);

            if (response.status === HTTP.STATUS.SUCCESS) {
                toast({
                    title: 'Registro Atualizado',
                    description: 'Informações atualizadas com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });
            }
        },
        [toast]
    );

    useEffect(() => {
        changeTitle('Configurarações Gerais');

        apiGateway.get('/settings/integrations').then((response) => {
            setIntegrationSetting(response.data);
        });
    }, [changeTitle]);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex width="100%" flexDirection="column" fontSize="12px">
                {integrationSettings !== null && (
                    <Flex width="100%" flexDirection="column" alignItems="center">
                        <Flex
                            width="100%"
                            maxWidth="400px"
                            flexDirection="column"
                            backgroundColor="white"
                            px="24px"
                        >
                            <Flex
                                width="100%"
                                py="16px"
                                justifyContent="space-between"
                                flexWrap="wrap"
                            >
                                <Flex width="100%" flexDirection="column">
                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Google Ads
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="google_ads_id"
                                                    label="Google Ads ID"
                                                    size="sm"
                                                    placeholder="Ex.: AW-000000000"
                                                    defaultValue={integrationSettings.google_ads_id}
                                                />
                                            </Flex>
                                            <Flex width="100%">
                                                <Input
                                                    name="google_ads_conversion_id"
                                                    label="Google Conversion Target"
                                                    size="sm"
                                                    placeholder="Ex.: -vFCCNHh7fYBELCB6sUB"
                                                    defaultValue={
                                                        integrationSettings.google_ads_conversion_id
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Google Analytics
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="google_analytics_id"
                                                    label="ID"
                                                    size="sm"
                                                    placeholder="Ex.: UA-000000000-0"
                                                    defaultValue={
                                                        integrationSettings.google_analytics_id
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Google Tag Manager
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="google_tag_manager"
                                                    label="Tag Manager"
                                                    size="sm"
                                                    placeholder="Ex.: GTM-000000000"
                                                    defaultValue={
                                                        integrationSettings.google_tag_manager
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Google Domain Verification
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="google_domain_verification"
                                                    label="Código"
                                                    size="sm"
                                                    placeholder="Ex.: 00000000000000000000000"
                                                    defaultValue={
                                                        integrationSettings.google_domain_verification
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Facebook Pixel
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="facebook_pixel_id"
                                                    label="Facebook Pixel ID"
                                                    size="sm"
                                                    placeholder="Ex.: 00000000000000"
                                                    defaultValue={
                                                        integrationSettings.facebook_pixel_id
                                                    }
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Jivochat
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="jivochat_key"
                                                    label="Jivochat ID"
                                                    size="sm"
                                                    placeholder="Ex.: ShdMKlTDl74"
                                                    defaultValue={integrationSettings.jivochat_key}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex width="100%" flexDirection="column">
                                        <Flex
                                            width="100%"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                        >
                                            <Text mr="16px" fontSize="16px" color="purple.500">
                                                Zendesk Chat
                                            </Text>
                                        </Flex>

                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%">
                                                <Input
                                                    name="zendesk_key"
                                                    label="Zendesk Chat"
                                                    size="sm"
                                                    placeholder="Ex.: 78COAtMfferk6bATmTJkUngEEiXiLtev"
                                                    defaultValue={integrationSettings.zendesk_key}
                                                />
                                            </Flex>
                                        </Flex>
                                    </Flex>

                                    <Flex
                                        width="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                        px="8px"
                                        py="4px"
                                        backgroundColor="yellow.100"
                                        border="2px solid"
                                        borderColor="yellow.500"
                                        color="gray.800"
                                        flexWrap="nowrap"
                                        fontSize="12px"
                                    >
                                        <Text mr="8px" color="purple.500">
                                            Atenção, recomendamos que utilize apenas um chat, deixe
                                            o outro em branco.
                                        </Text>
                                    </Flex>

                                    <Flex width="100%">
                                        <Flex width="100%" mt="16px">
                                            <Flex width="100%">
                                                <Button
                                                    type="submit"
                                                    width="100%"
                                                    backgroundColor="purple.500"
                                                    py="8px"
                                                    color="white"
                                                    _hover={{
                                                        backgroundColor: 'green.500'
                                                    }}
                                                    fontSize="18px"
                                                    fontWeight="500"
                                                    borderRadius="2px"
                                                >
                                                    Confirmar
                                                </Button>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                                <Divider my="0px" />
                            </Flex>
                            <Divider my="0px" />
                        </Flex>
                    </Flex>
                )}
            </Flex>
        </Form>
    );
};

export default AnalyticsChatPage;
