import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Box, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import InputCurrency from '../../../../components/Form/InputCurrency';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';
import NumberUtils from '../../../../shared/utils/NumberUtils';


type SetProps = {
    id: string;
    title: string;
    list: string[];
    order_by: 'alphabetical' | 'popularity' | 'price_asc' | 'price_desc'
    priority: number;
    is_enable: boolean;
}

const SetsRegister: React.FC = () => {
    const formRef = useRef<FormHandles>();
    const navigate = useNavigate();
    const toast = useToast();
    const [list, setList] = useState([]);
    const [order, setOrder] = useState('alphabetical');
    const [isEnable, setIsEnable] = useState(false);
    const [categories, setCategories] = useState([]);

    const handleChangeList = useCallback((value) => {
        if(!value) return;
        setList((oldState) => {
            const newValue = oldState;
            if(newValue.includes(value))
                return newValue.filter((item) => item !== value);
            return [...newValue, value];
        });
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.post('/settings/sets', {
                    title: formData.title,
                    list,
                    order_by: order,
                    priority: formData.priority,
                    is_enable: isEnable,
                });
                if (response.status === HTTP.STATUS.CREATED) {
                    FormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Conjunto cadastrado com sucesso',
                        toast
                    );
                    FormHelper.reset(formRef as any);
                    navigate('/settings/sets');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [isEnable, history, toast, list, order, isEnable]
    );

    useEffect(() => {
        apiGateway.get('/categories').then(response => {
            setCategories(response.data);
        });
    }, []);

    return (
        <Form
            ref={formRef as any}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Flex
                width="100%"
                maxWidth="800px"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Registrar novo conjunto
                </Text>

                <Flex width="100%" flexDirection="column">
                    <Input name="title" label="Nome do Conjunto" />

                    <Flex width="100%" flexDirection="column">
                        <Box fontWeight="500" mr="48px">
                            <Text>Selecione os grupo dos produtos que deseja adicionar no conjunto</Text>
                            <Flex
                                width="100%"
                                flexWrap="wrap"
                                mt="8px"
                            >
                                <Checkbox
                                    name="list"
                                    isChecked={list.includes('highlights')}
                                    onChange={() => handleChangeList('highlights')}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Destaques</Text>
                                </Checkbox>
                                {categories.map((category) => (
                                    <Checkbox
                                        key={category.id}
                                        name="list"
                                        isChecked={list.includes(category.name)}
                                        onChange={() => handleChangeList(category.name)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">{category.name}</Text>
                                    </Checkbox>
                                ))}
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex mt="16px">
                        <Input name="priority" label="Prioridade (quanto menor o valor maior a prioridade)" typeInput='number' />
                    </Flex>

                    <Flex width="100%" flexDirection="column">
                        <Box fontWeight="500" mr="48px">
                            <Text>Tipo de ordenação</Text>
                            <Flex
                                width="100%"
                                flexWrap="wrap"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={order === 'alphabetical'}
                                    onClick={() => setOrder('alphabetical')}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Alfabética</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_enable"
                                    isChecked={order === 'price_asc'}
                                    onClick={() => setOrder('price_asc')}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Preço (Crescente)</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_enable"
                                    isChecked={order === 'price_desc'}
                                    onClick={() => setOrder('price_desc')}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Preço (Descrescente)</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>

                    <Flex width="100%" flexDirection="column" mt="16px">
                        <Box fontWeight="500">
                            <Text>Ativo?</Text>
                            <Flex
                                width="100%"
                                flexWrap="wrap"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable}
                                    onChange={() => setIsEnable(!isEnable)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable === false}
                                    onChange={() => setIsEnable(!isEnable)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
          Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default SetsRegister;
