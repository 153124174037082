import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const MakeBasketEdit: React.FC = () => {
    const { changeTitle } = useLayout();

    const [category, setCategory] = useState(null);
    const [isRequired, setIsRequired] = useState(false);
    const [isEnable, setIsEnable] = useState(false);

    const { id } = useParams() as any;

    const formRef = useRef<FormHandles>();
    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = useCallback(
        async (formData: any) => {
            apiGateway
                .put(`/products/subtypes/${id}`, {
                    ...formData,
                    is_enable: isEnable,
                    is_required: isRequired
                })
                .then((response) => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        toast({
                            title: 'Atualização efetuada',
                            description: 'Registro atualizado com succeso',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });
                    }
                });
        },
        [navigate, isEnable, isRequired, toast]
    );

    useEffect(() => {
        changeTitle('Monte Sua Cesta');
        apiGateway.get(`/products/subtypes/${id}`).then(({ status, data }) => {
            if (status === HTTP.STATUS.SUCCESS) {
                setCategory(data);
                setIsEnable(data.is_enable);
                setIsRequired(data.is_required);
            }
        });
    }, []);

    return (
        category && (
            <Form
                ref={formRef}
                onSubmit={handleSubmit}
                autoComplete="off"
                style={{
                    width: '100%'
                }}
            >
                <Flex
                    width="100%"
                    maxWidth="800px"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
                        Informações Básicas
                    </Text>

                    <Flex minWidth="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                        <Box width="48%">
                            <Input
                                name="value"
                                label="Nome da Categoria"
                                isRequired
                                size="sm"
                                defaultValue={category.value}
                            />
                        </Box>

                        <Flex width="100%">
                            <Box fontWeight="500" mr="48px">
                                <Text>Obrigatória?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_required"
                                        isChecked={isRequired === false}
                                        onChange={() => setIsRequired(!isRequired)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_required"
                                        isChecked={isRequired}
                                        onChange={() => setIsRequired(!isRequired)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>

                            <Box fontWeight="500" mr="48px">
                                <Text>Ativar Categoria?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable === false}
                                        onChange={() => setIsEnable(!isEnable)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_enable"
                                        isChecked={isEnable}
                                        onChange={() => setIsEnable(!isEnable)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
                        Atualizar
                    </Button>
                </Flex>
            </Form>
        )
    );
};

export default MakeBasketEdit;
