import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import Select from '../../../../components/Form/Select';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';

const UsersEdit: React.FC = () => {
    const { id } = useParams();
    const formRef = useRef<FormHandles>();
    const { store } = useAuth();
    const { changeTitle } = useLayout();
    const navigate = useNavigate();

    const toast = useToast();
    const [user, setUser] = useState(null as any);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                if(!formData.email) {
                    toast({
                        title: 'Campos obrigatório',
                        description: 'Informe o login',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }

                const response = await apiGateway.put(`/users/${id}`, {
                    name: formData.name,
                    email: formData.email,
                    password: formData.password,
                    permission: formData.permission,
                    store_id: store,
                });

                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess(
                        'Atualização Realizada',
                        'Usuário atualizado com sucesso',
                        toast
                    );
                    FormHelper.reset(formRef as any);
                    navigate('/settings/users');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [store, navigate, toast, id]
    );

    useEffect(() => {
        changeTitle('Atualizar Usuário');
        apiGateway.get(`/users/${id}`)
            .then((response) => {
                const { status, data } = response;
                if(status === HTTP.STATUS.SUCCESS)
                    setUser(data);
            });
    }, [id]);

    return user && (
        <Form
            ref={formRef as any}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            <Flex
                width="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
          Dados do Usuário
                </Text>

                <Flex width="50%" flexDirection="column">
                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="48%">
                            <Input name="name" label="Nome" defaultValue={user.name}/>
                        </Flex>
                        <Flex width="48%">
                            <Select name="permission" label="Permissão" defaultValue={user.permission}>
                                <option>Admin</option>
                                <option>Florista</option>
                                <option>Atendente</option>
                            </Select>
                        </Flex>
                    </Flex>

                    <Flex width="100%" justifyContent="space-between">
                        <Flex width="48%">
                            <Input name="email" label="Login" isRequired defaultValue={user.email}/>
                        </Flex>
                        <Flex width="48%">
                            <Input name="password" label="Senha" isRequired />
                        </Flex>
                    </Flex>

                    
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Atualizar
                </Button>
            </Flex>
        </Form>
    );
};

export default UsersEdit;
