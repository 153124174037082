import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Flex, Image, Text } from '@chakra-ui/core';

import successLogo from '../../../assets/images/check-success.png';
import logo from '../../../assets/logo-white.png';

const StoreSuccess: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Flex width="100%" height="100vh">
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="purple.500"
                height="100%"
            >
                <Box px="160px">
                    <Image src={logo} alt="Logo E-Florista" />
                </Box>
            </Flex>
            <Flex
                width="50%"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <Image src={successLogo} width="56px" height="56px" />
                <Text fontWeight="500" fontSize="18px" my="8px">
                    Loja criada com sucesso!
                </Text>
                <Button
                    onClick={() => navigate('/login')}
                    backgroundColor="purple.500"
                    color="white"
                    fontWeight="500"
                    mt="4"
                    _hover={{
                        backgroundColor: 'green.500'
                    }}
                >
                    Ir para Login
                </Button>
            </Flex>
        </Flex>
    );
};

export default StoreSuccess;
