import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { DayModifiers } from 'react-day-picker';
import { FiCalendar, FiCheck, FiPrinter } from 'react-icons/fi';
import { useReactToPrint } from 'react-to-print';

import { Collapse, Divider, Flex, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import Calendar from '../../../components/Form/Calendar';
import Select from '../../../components/Form/Select';
import PanelButton from '../../../components/PanelButton';
import { useLayout } from '../../../layouts/default';
import apiGateway from '../../../shared/services/apiGateway';
import CurrencyUtils from '../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../shared/utils/DateUtils';
import TextUtils from '../../../shared/utils/TextUtils';

export type FilterType =
  | 'DAILY_REPORT'
  | 'CLOSING_REPORT'
  | 'GENERAL_REPORT'
  | 'DELIVERY_REPORT';

const RelatoriesPage: React.FC = () => {
    const navigate = useNavigate();
    const [filter, setFilter] = useState<FilterType>('DAILY_REPORT');
    const formRef = useRef<FormHandles>(null);
    const { changeTitle } = useLayout();

    const [showCalendar, setShowCalendar] = useState(false);
    const [periodDate1Filter, setPeriodDate1Filter] = useState(new Date());
    const [periodDate2Filter, setPeriodDate2Filter] = useState(new Date());

    const [paymentStatusFilter, setPaymentStatusFilter] = useState(null);
    const [cities, setCities] = useState([]);
    const [cityFilter, setCityFilter] = useState('');

    const [reportData, setReportData] = useState({
        orders: [],
        total_delivery: 0,
        total_creditcard: 0,
        total_deposit: 0,
        total_link: 0,
        total_others: 0,
        total_brute: 0
    } as any);

    const printComponentRef = useRef();

    const loadingClosingReport = useCallback(async () => {
        const response = await apiGateway.get('/relatories/closing_report', {
            params: {
                date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd'))
            }
        });

        setReportData(response.data);
    }, [periodDate1Filter, periodDate2Filter]);

    const loadingGeneralReport = useCallback(async () => {
        const response = await apiGateway.get('/relatories/general_report', {
            params: {
                date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd')),
                status: paymentStatusFilter
            }
        });

        setReportData(response.data);
    }, [paymentStatusFilter, periodDate1Filter, periodDate2Filter]);

    const loadingDailyReport = useCallback(async () => {
        const response = await apiGateway.get('/relatories/daily_report', {
            params: {
                date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd')),
                status: paymentStatusFilter
            }
        });

        const dataFormated = response.data;

        let { orders } = dataFormated;

        orders = orders.sort((a, b): any => {
            return format(parseISO(b.created_at), 'yyyyMMdd') >
        format(parseISO(a.created_at), 'yyyyMMdd')
                ? -1
                : 1;
        });

        dataFormated.orders = orders;

        setReportData(dataFormated);
    }, [paymentStatusFilter, periodDate1Filter, periodDate2Filter]);

    const loadingDeliveryReport = useCallback(async () => {
        const response = await apiGateway.get('/relatories/delivery_report', {
            params: {
                date1: new Date(format(periodDate1Filter, 'yyyy-MM-dd')),
                date2: new Date(format(periodDate2Filter, 'yyyy-MM-dd')),
                city: cityFilter
            }
        });

        setReportData(response.data);
        setCities([...new Set(response.data.cities)].sort((one, two) =>
            one > two ? -1 : 1));
    }, [periodDate1Filter, periodDate2Filter, cityFilter]);

    const handleChangeRelatory = useCallback(
        async (filterType: FilterType) => {
            setFilter(filterType);

            if (filterType === 'CLOSING_REPORT') {
                loadingClosingReport();
            } else if (filterType === 'GENERAL_REPORT') {
                loadingGeneralReport();
            } else if (filterType === 'DAILY_REPORT') {
                loadingDailyReport();
            } else {
                loadingDeliveryReport();
            }
        },
        [
            loadingClosingReport,
            loadingDailyReport,
            loadingDeliveryReport,
            loadingGeneralReport
        ]
    );

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    const handleChangePeriodDate1Filter = useCallback(
        (date: Date, modifiers: DayModifiers) => {
            if (modifiers.available && !modifiers.disabled) {
                setPeriodDate1Filter(date);
            }
        },
        []
    );

    const handleChangePeriodDate2Filter = useCallback(
        (date: Date, modifiers: DayModifiers) => {
            if (modifiers.available && !modifiers.disabled) {
                setPeriodDate2Filter(date);
            }
        },
        []
    );

    const title = {
        DAILY_REPORT: 'Resumo por Dia',
        CLOSING_REPORT: 'Relatório de Fechamento',
        GENERAL_REPORT: 'Relatório Geral',
        DELIVERY_REPORT: 'Relatório de Entregas'
    };

    const handleSearch = useCallback(async () => {
        if (filter === 'CLOSING_REPORT') {
            loadingClosingReport();
        } else if (filter === 'GENERAL_REPORT') {
            loadingGeneralReport();
        } else if (filter === 'DAILY_REPORT') {
            loadingDailyReport();
        } else {
            loadingDeliveryReport();
        }

        setShowCalendar(false);
    }, [
        filter,
        loadingClosingReport,
        loadingDailyReport,
        loadingDeliveryReport,
        loadingGeneralReport
    ]);

    useEffect(() => {
        changeTitle('Relatórios');
        handleChangeRelatory(filter);
    }, [filter, handleChangeRelatory]);

    return (
        <Flex width="100%" flexDirection="column" backgroundColor="white" p="16px">
            <Flex width="100%">
                <Flex width="50%" justifyContent="flex-start" alignItems="center">
                    <Text mr="16px" fontSize="18px" color="purple.500">
                        {title[filter]}
                    </Text>

                    {filter !== 'DELIVERY_REPORT' && (
                        <Text mr="16px" fontSize="14px" color="green.500">
                            {`Total: ${CurrencyUtils.numberToCurrency(
                                reportData.total_brute
                            )}`}
                        </Text>
                    )}
                </Flex>
                <Flex width="50%" justifyContent="flex-end" alignItems="center">
                    <Text fontSize="12px" mr="16px">
            Mostrar:
                    </Text>

                    <PanelButton
                        maxWidth="160px"
                        text="Resumo por Dia"
                        isSelected={filter === 'DAILY_REPORT'}
                        buttonFunction={() => handleChangeRelatory('DAILY_REPORT')}
                    />

                    <PanelButton
                        maxWidth="160px"
                        text="Relatório de Fechamento"
                        isSelected={filter === 'CLOSING_REPORT'}
                        buttonFunction={() => handleChangeRelatory('CLOSING_REPORT')}
                    />

                    <PanelButton
                        maxWidth="160px"
                        text="Relatório Geral"
                        isSelected={filter === 'GENERAL_REPORT'}
                        buttonFunction={() => handleChangeRelatory('GENERAL_REPORT')}
                    />

                    <PanelButton
                        maxWidth="160px"
                        text="Relatório de Entregas"
                        isSelected={filter === 'DELIVERY_REPORT'}
                        buttonFunction={() => handleChangeRelatory('DELIVERY_REPORT')}
                    />

                    <PanelButton
                        maxWidth="160px"
                        text="Relatório de Produtos"
                        buttonFunction={() => navigate('/relatories/products')}
                    />
                </Flex>
            </Flex>
            <Divider my="8px" />
            <Flex width="100%">
                {(filter === 'DAILY_REPORT' ||
          filter === 'CLOSING_REPORT' ||
          filter === 'GENERAL_REPORT' ||
          filter === 'DELIVERY_REPORT') && (
                    <Flex width="50%" alignItems="center">
                        <Text width="96px" fontSize="12px">
                        Data:
                        </Text>

                        <Flex flexDirection="column" mr="16px">
                            <Button
                                width="144px"
                                backgroundColor="purple.500"
                                borderRadius="2px"
                                size="sm"
                                height="32px"
                                py="0px"
                                fontSize="12px"
                                display="flex"
                                alignItems="center"
                                _focus={{
                                    outline: 'none'
                                }}
                                _hover={{
                                    backgroundColor: 'purple.500'
                                }}
                                onClick={() => setShowCalendar(oldState => !oldState)}
                            >
                                <FiCalendar size={16} />
                                <Text ml="8px">
                                    {`De ${format(periodDate1Filter, 'dd/MM/yyyy')}`}
                                </Text>
                            </Button>

                            <Collapse width="100%" isOpen={showCalendar} display="flex">
                                <Calendar
                                    isErrored={false}
                                    selectedDate={periodDate1Filter}
                                    handleDateChange={handleChangePeriodDate1Filter}
                                    handleMonthChange={undefined}
                                />
                            </Collapse>
                        </Flex>

                        <Flex flexDirection="column">
                            <Button
                                width="144px"
                                backgroundColor="purple.500"
                                borderRadius="2px"
                                size="sm"
                                height="32px"
                                py="0px"
                                fontSize="12px"
                                display="flex"
                                alignItems="center"
                                _focus={{
                                    outline: 'none'
                                }}
                                _hover={{
                                    backgroundColor: 'purple.500'
                                }}
                                onClick={() => setShowCalendar(oldState => !oldState)}
                            >
                                <FiCalendar size={16} />
                                <Text ml="8px">
                                    {`Até ${format(periodDate2Filter, 'dd/MM/yyyy')}`}
                                </Text>
                            </Button>

                            <Collapse width="100%" isOpen={showCalendar} display="flex">
                                <Calendar
                                    isErrored={false}
                                    selectedDate={periodDate2Filter}
                                    handleDateChange={handleChangePeriodDate2Filter}
                                    handleMonthChange={undefined}
                                />
                            </Collapse>
                        </Flex>
                    </Flex>
                )}
            </Flex>

            <Flex width="100%" mt="8px" justifyContent="flex-end">
                {(filter === 'GENERAL_REPORT' || filter === 'DAILY_REPORT') && (
                    <Flex width="50%" alignItems="center">
                        <Text width="96px" fontSize="12px">
              Status:
                        </Text>

                        <PanelButton
                            text="Todas"
                            isSelected={paymentStatusFilter === null}
                            buttonFunction={() => setPaymentStatusFilter(null)}
                        />

                        <PanelButton
                            text="Aprovadas"
                            isSelected={paymentStatusFilter === 'APROVADO'}
                            buttonFunction={() => setPaymentStatusFilter('APROVADO')}
                        />

                        <PanelButton
                            text="Pendentes"
                            isSelected={paymentStatusFilter === 'PENDENTE'}
                            buttonFunction={() => setPaymentStatusFilter('PENDENTE')}
                        />

                        <PanelButton
                            text="Negadas"
                            isSelected={paymentStatusFilter === 'NEGADO'}
                            buttonFunction={() => setPaymentStatusFilter('NEGADO')}
                        />
                    </Flex>
                )}

                {filter === 'DELIVERY_REPORT' && (
                    <Form
                        ref={formRef as any}
                        style={{ width: '100%' }}
                        onSubmit={() => null}
                    >
                        <Flex width="50%" alignItems="center">
                            <Text width="96px" fontSize="12px">
                                Cidade:
                            </Text>

                            <Select
                                name="city"
                                size="sm"
                                mb="0px"
                                width="200px"
                                onChange={e => {
                                    setCityFilter(e.currentTarget.value);
                                }}
                            >
                                <option value="">Todas</option>
                                {cities.map(city => (
                                    <option key={city} value={city}>
                                        {city}
                                    </option>
                                ))}
                            </Select>
                        </Flex>
                    </Form>
                )}

                <Flex width="50%" justifyContent="flex-end">
                    <Button
                        width="104px"
                        backgroundColor="purple.500"
                        height="32px"
                        borderRadius="2px"
                        mr="16px"
                        _hover={{
                            backgroundColor: 'purple.500'
                        }}
                        _focus={{
                            backgroundColor: 'purple.500'
                        }}
                    >
                        <Flex alignItems="center" onClick={() => handlePrint()}>
                            <Text fontSize="14px" lineHeight="14px" mr="16px">
                Imprimir
                            </Text>
                            <FiPrinter size={16} />
                        </Flex>
                    </Button>

                    <Button
                        width="104px"
                        backgroundColor="green.500"
                        height="32px"
                        borderRadius="2px"
                        onClick={() => handleSearch()}
                    >
                        <Flex alignItems="center">
                            <Text fontSize="14px" lineHeight="14px" mr="16px">
                Buscar
                            </Text>
                            <FiCheck size={16} />
                        </Flex>
                    </Button>
                </Flex>
            </Flex>

            <Divider my="8px" />

            {reportData !== null && (
                <Flex ref={printComponentRef} width="100%" flexDirection="column">
                    <Flex width="100%" flexDirection="column">
                        {filter === 'CLOSING_REPORT' && (
                            <Flex
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                fontSize="12px"
                                maxWidth="100%"
                            >
                                <Flex width="100%" justifyContent="center" maxWidth="96px">
                                    <Text>Pedido</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Valor de Entrega</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Total Bruto</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Valor de Comissão</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Total Liquido</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Data</Text>
                                </Flex>
                            </Flex>
                        )}

                        {filter === 'DAILY_REPORT' && (
                            <Flex width="100%" py="8px" fontSize="12px" maxWidth="100%">
                                <Flex width="100%" justifyContent="center" minWidth="40px">
                                    <Text>#</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" minWidth="120px">
                                    <Text>Data</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" minWidth="120px">
                                    <Text>Valor Vendido</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" minWidth="120px">
                                    <Text>Status</Text>
                                </Flex>
                            </Flex>
                        )}

                        {filter === 'GENERAL_REPORT' && (
                            <Flex
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                fontSize="14px"
                                maxWidth="100%"
                            >
                                <Flex width="100%" justifyContent="center">
                                    <Text>Cliente</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Total Bruto</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Total Líquido</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <Text>Status de Pagamento</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Data</Text>
                                </Flex>
                            </Flex>
                        )}

                        {filter === 'DELIVERY_REPORT' && (
                            <Flex
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                fontSize="14px"
                                maxWidth="100%"
                            >
                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <Text>Data/Hora</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" maxWidth="96px">
                                    <Text>Pedido</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Cidade</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Total Bruto</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <Text>Status</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Produto</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>Observações</Text>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>

                    {filter === 'CLOSING_REPORT' &&
                        reportData?.orders?.map(order => (
                            <Flex
                                key={order.id}
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                fontSize="14px"
                                maxWidth="100%"
                            >
                                <Flex width="100%" justifyContent="center" maxWidth="96px">
                                    <Text>{order.code}</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(order.delivery_value)}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(order.total_value)}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(order.total_comission)}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>{CurrencyUtils.numberToCurrency(order.net_value)}</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>{DateUtils.format(order.created_at)}</Text>
                                </Flex>
                            </Flex>
                        ))}

                    {filter === 'GENERAL_REPORT' &&
                        reportData?.orders?.map(order => (
                            <Flex
                                key={order.id}
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                fontSize="14px"
                                maxWidth="100%"
                            >
                                <Flex width="100%" justifyContent="center">
                                    <Text>{order?.buyer?.name}</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(order.total_value)}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>{CurrencyUtils.numberToCurrency(order.net_value)}</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <Text textAlign="center">
                                        {TextUtils.convertStatusPayment(order.payment_status)}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>{DateUtils.format(order.created_at)}</Text>
                                </Flex>
                            </Flex>
                        ))}

                    {filter === 'DAILY_REPORT' &&
            reportData?.totalDay &&
            Object.keys(reportData?.totalDay).map((key, index) => (
                <Flex
                    key={key}
                    width="100%"
                    py="8px"
                    fontSize="14px"
                    maxWidth="100%"
                >
                    <Flex width="100%" justifyContent="center" minWidth="40px">
                        <Text>{index + 1}</Text>
                    </Flex>
                    <Flex width="100%" justifyContent="center" minWidth="120px">
                        <Text>{key}</Text>
                    </Flex>

                    <Flex width="100%" justifyContent="center" minWidth="120px">
                        <Text>
                            {CurrencyUtils.numberToCurrency(reportData.totalDay[key])}
                        </Text>
                    </Flex>

                    <Flex width="100%" justifyContent="center" minWidth="120px">
                        <Text textAlign="center">
                            {!paymentStatusFilter ? 'TODOS' : paymentStatusFilter}
                        </Text>
                    </Flex>
                </Flex>
            ))}

                    {filter === 'DELIVERY_REPORT' && reportData.orders &&
                        reportData?.orders?.map(order => (
                            <Flex
                                key={order.id}
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                fontSize="14px"
                                maxWidth="100%"
                            >
                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <Text>
                                        {`${DateUtils.format(order.delivery_date, 'dd/MM')} - ${
                                            order.delivery_hour
                                        }`}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" maxWidth="96px">
                                    <Text>{order.code}</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {`${
                                            order.delivery_address
                                                ? order.delivery_address.city
                                                : 'Não Informada'
                                        }${
                                            order.delivery_address
                                                ? ` - ${order.delivery_address.neighborhood}`
                                                : ''
                                        }`}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(order.total_value)}
                                    </Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <Text textAlign="center">
                                        {TextUtils.convertStatusPayment(order.payment_status)}
                                    </Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    flexDirection="column"
                                    textAlign="center"
                                >
                                    <Text>{order.products ? order?.products[0]?.name : ''}</Text>
                                    <Text>{order.products ? order?.products[1]?.name : ''}</Text>
                                    <Text>{order.products ? order?.products[2]?.name : ''}</Text>
                                    <Text>{order.products ? order?.products[3]?.name : ''}</Text>
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    flexDirection="column"
                                >
                                    <Text textAlign="center">
                                        {order.details
                                            ? order.order_details.observations
                                            : 'Não Informado'}
                                    </Text>
                                </Flex>
                            </Flex>
                        ))}

                    {reportData !== null && reportData.orders?.length <= 0 && (
                        <Flex px="24px" py="16px">
                            <Text color="gray.700" fontSize="18px">
                Nenhum resultado encontrado
                            </Text>
                        </Flex>
                    )}

                    <Divider my="8px" />

                    {reportData != null && filter === 'CLOSING_REPORT' && (
                        <Flex
                            width="100%"
                            mt="auto"
                            height="100%"
                            alignItems="center"
                            px="8px"
                        >
                            <Flex
                                width="100%"
                                ml="auto"
                                alignItems="flex-end"
                                flexDirection="column"
                            >
                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Bruto (Cartão):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_creditcard_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Líquido (Cartão):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_creditcard
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Bruto (Depósito):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_deposit_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Líquido (Depósito):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(reportData.total_deposit)}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Bruto (Link):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_link_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Líquido (Link):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(reportData.total_link)}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Bruto (Pag. na entrega e loja):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_others_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Líquido (Pag. na entrega e loja):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(reportData.total_others)}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="16px" mt="8px">
                                    <Text mr="8px">Total Final Bruto:</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(reportData.total_brute)}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}

                    {reportData != null && filter === 'GENERAL_REPORT' && (
                        <Flex
                            width="100%"
                            mt="auto"
                            height="100%"
                            alignItems="center"
                            px="8px"
                        >
                            <Flex
                                width="100%"
                                ml="auto"
                                alignItems="flex-end"
                                flexDirection="column"
                            >
                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Cartão de Crédito:</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_creditcard_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Depósito:</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_deposit_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total Link:</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_link_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="12px">
                                    <Text mr="8px">Total (Pag. na entrega e loja):</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(
                                            reportData.total_others_brute
                                        )}
                                    </Text>
                                </Flex>

                                <Flex alignItems="center" fontSize="16px" mt="8px">
                                    <Text mr="8px">Total FinaL:</Text>
                                    <Text>
                                        {CurrencyUtils.numberToCurrency(reportData.total_brute)}
                                    </Text>
                                </Flex>
                            </Flex>
                        </Flex>
                    )}

                    <Divider my="8px" />
                </Flex>
            )}
        </Flex>
    );
};

export default RelatoriesPage;
