import { Badge, Checkbox, Divider, Flex, PseudoBox, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiEdit2, FiPlus, FiSave, FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Input from '../../../../components/Form/Input';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const MakeBasketSettings: React.FC = () => {
    const { changeTitle } = useLayout();
    const navigate = useNavigate();

    const [selectedIds, setSelectedIds] = useState([]);
    const [categories, setCategories] = useState([]);
    const [generalSettings, setGeneralSetting] = useState(null);
    const [isEnableSite, setIsEnableSite] = useState(false);

    const [ordersCategory, setOrdersCategory] = useState({});

    const formRef = useRef<FormHandles>(null);
    const toast = useToast();

    const handleChangeSelectedIds = useCallback(
        (input: any) => {
            let list = selectedIds;

            if (input.checked) {
                if (!selectedIds.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter((item) => item !== input.value);
            }

            setSelectedIds(list);
        },
        [selectedIds]
    );

    const handleEdit = useCallback(
        (id: string) => {
            navigate(`/settings/make_basket/edit/${id}`);
        },
        [navigate]
    );

    const handleDelete = useCallback(() => {
        apiGateway
            .delete('/products/subtypes', { params: { ids: selectedIds } })
            .then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    toast({
                        title: 'Categoria removida',
                        description: '',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });

                    navigate(0);
                }
            });
    }, [selectedIds]);

    const CategoryRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={(e) => handleChangeSelectedIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Text>{data[index].value}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Badge
                        width="80px"
                        textAlign="center"
                        backgroundColor={data[index].is_enable ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                        fontSize="10px"
                    >
                        {data[index].is_enable ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>

                <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    maxWidth="144px"
                    style={{ gap: '4px' }}
                >
                    <Input
                        width="96px"
                        height="28px"
                        mb="0px"
                        mx="auto"
                        textAlign="center"
                        typeInput="number"
                        name={`order_${data[index].value}`}
                        onChange={(e) => {
                            setOrdersCategory((oldState) => {
                                return {
                                    ...oldState,
                                    ...{ [data[index].value]: e.target.value }
                                };
                            });
                        }}
                        value={ordersCategory[data[index].value] || 0}
                        //defaultValue={0}
                    />
                    <Button
                        rounded="4px"
                        backgroundColor="green.500"
                        title="Salvar"
                        onClick={() => handleSaveOrder(data[index])}
                    >
                        <FiSave />
                    </Button>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Text>{data[index].is_required ? 'Sim' : 'Não'}</Text>
                </Flex>
                <Flex width="100%" justifyContent="center" maxWidth="104px">
                    <PseudoBox
                        title="Editar"
                        p="8px"
                        backgroundColor="purple.500"
                        color="white"
                        borderRadius="2px"
                        cursor="pointer"
                        onClick={() => handleEdit(data[index].id)}
                    >
                        <FiEdit2 size={14} />
                    </PseudoBox>
                </Flex>
            </ListRow>
        );
    };

    const handleSaveOrder = useCallback(
        async (category: any) => {
            apiGateway
                .put(`/products/subtypes/${category.id}`, {
                    ...category,
                    order: ordersCategory[category.value]
                })
                .then((response) => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        toast({
                            title: 'Atualização efetuada',
                            description: 'Registro atualizado com succeso',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });
                    }
                });
        },
        [ordersCategory]
    );

    const handleSave = useCallback(async () => {
        await apiGateway.put('/settings/general_settings', {
            ...generalSettings,
            is_enable_make_basket_site: isEnableSite,
            min_value_make_basket: formRef.current.getFieldValue('min_value')
        });

        toast({
            title: 'Configuração Atualizada',
            description: '',
            status: 'success',
            duration: 2000,
            isClosable: true
        });
    }, [generalSettings, isEnableSite]);

    useEffect(() => {
        changeTitle('Configurações');

        apiGateway.get('/settings/general_settings').then(({ status, data }) => {
            if (status === 200) {
                setGeneralSetting(data);
                setIsEnableSite(data.is_enable_make_basket_site);
            }
        });

        apiGateway.get('/products/subtypes?query=').then(({ status, data }) => {
            if (status === 200) {
                setCategories(data);
                const provData = {};
                data.forEach((item) => {
                    provData[item.value] = item.order;
                });

                setOrdersCategory(provData);
            }
        });
    }, []);

    return (
        generalSettings && (
            <Form ref={formRef as any} onSubmit={null}>
                <Flex
                    width="100%"
                    maxWidth="800px"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontWeight="500"
                    fontSize="14px"
                >
                    <Text mr="16px" fontSize="24px" color="purple.500">
                        Monte sua Cesta
                    </Text>
                    <Flex width="100%" flexDirection="column">
                        <Flex width="100%" justifyContent="space-between">
                            <Flex width="100%" justifyContent="space-between" alignItems="center">
                                <Checkbox
                                    variantColor="green"
                                    size="sm"
                                    borderColor="gray.700"
                                    style={{
                                        outline: 'none'
                                    }}
                                    name="is_enable_site"
                                    isChecked={isEnableSite === true}
                                    onChange={() => setIsEnableSite(!isEnableSite)}
                                >
                                    Ativar no Site
                                </Checkbox>

                                <Flex width="120px">
                                    <Input
                                        width="120px"
                                        label="Valor Mínimo"
                                        name="min_value"
                                        defaultValue={generalSettings.min_value_make_basket}
                                        size="sm"
                                    />
                                </Flex>

                                <PseudoBox
                                    p="8px"
                                    backgroundColor="green.500"
                                    color="white"
                                    borderRadius="4px"
                                    cursor="pointer"
                                    rounded="8px"
                                    onClick={() => handleSave()}
                                >
                                    Salvar
                                </PseudoBox>
                            </Flex>
                        </Flex>

                        <Flex width="100%" flexDirection="column">
                            <Flex width="100%" flexDirection="column" backgroundColor="white">
                                <Flex
                                    width="100%"
                                    py="8px"
                                    justifyContent="space-between"
                                    flexWrap="wrap"
                                >
                                    <Flex
                                        width="100%"
                                        maxWidth="800px"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                    >
                                        <Text mr="16px" fontSize="14px">
                                            Categorias Monte sua Cesta
                                        </Text>

                                        <Flex style={{ gap: '4px' }}>
                                            <PseudoBox
                                                title="Nova Categoria"
                                                p="6px"
                                                backgroundColor="green.500"
                                                color="white"
                                                borderRadius="4px"
                                                cursor="pointer"
                                                rounded="8px"
                                                onClick={() =>
                                                    navigate('/settings/make_basket/register')
                                                }
                                            >
                                                <FiPlus size={14} />
                                            </PseudoBox>

                                            <PseudoBox
                                                title="Excluir"
                                                p="6px"
                                                backgroundColor="red.500"
                                                color="white"
                                                borderRadius="4px"
                                                cursor="pointer"
                                                rounded="8px"
                                                onClick={() => handleDelete()}
                                            >
                                                <FiTrash2 size={14} />
                                            </PseudoBox>
                                        </Flex>
                                    </Flex>
                                </Flex>

                                <Divider my="0px" />
                                <Flex
                                    width="100%"
                                    py="8px"
                                    justifyContent="space-between"
                                    fontSize="12px"
                                    maxWidth="100%"
                                >
                                    <Flex width="100%" justifyContent="center" maxWidth="40px">
                                        <Text>#</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="144px">
                                        <Text>Nome</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="144px">
                                        <Text>Status</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="144px">
                                        <Text>Ordem</Text>
                                    </Flex>

                                    <Flex width="100%" justifyContent="center" maxWidth="144px">
                                        <Text>Obrigatória</Text>
                                    </Flex>
                                    <Flex width="100%" justifyContent="center" maxWidth="104px">
                                        <Text>Ação</Text>
                                    </Flex>
                                </Flex>
                                <Divider my="0px" />

                                {categories.length > 0 && (
                                    <List item={categories} row={CategoryRow} itemHeight={48} />
                                )}

                                {categories.length <= 0 && <ListEmpty />}
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Form>
        )
    );
};

export default MakeBasketSettings;
