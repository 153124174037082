import apiGateway from '../../../shared/services/apiGateway';
import CategoryMap from '../mappers/CategoryMap';
import CategoryValidator from '../validators/CategoryValidator';

export default class CategoryHelper {
    public static categoryData: any;

    static async makeValidCategoryDataToSend({
        formData,
        icon_url,
        show_in_header: showInHeader,
        show_in_footer: showInFooter
    }) {
        const data = formData;

        data.icon_url = icon_url;
        data.show_in_header = showInHeader;
        data.show_in_footer = showInFooter;

        this.categoryData = CategoryMap.formDataToCreateDTO(data);

        await CategoryValidator.check(this.categoryData);

        return this.categoryData;
    }

    static async loadCategories() {
        const response = await apiGateway.get('/categories');

        return response.data;
    }
}
