import apiBrasil from "../services/apiBrasil";

export default class APIBrasilHelper {
    public static DEFAULT_DEVICE_ID = '008fec39-8cdb-48d0-a5f1-48e402916d2d';

    static async getToken() {
        const { status, data } = await apiBrasil.post('/login', {
            email: process.env.REACT_APP_ADMIN_WPE,
            password: process.env.REACT_APP_ADMIN_WPP
        })

        return { status, token: data?.authorization?.token }
    }

    static async getDevice(deviceId = '008fec39-8cdb-48d0-a5f1-48e402916d2d') {
        const { token } = await this.getToken();
        const { status, data } = await apiBrasil.get(`/devices/show?search=${deviceId}`, {
            headers: {
                'authorization': `Bearer ${token}`
            }
        });

        return { status, data }
    }

    static async getQRCode(deviceToken) {
        const { token } = await this.getToken();

        await apiBrasil.post('/whatsapp/start', {
            "powered_by": "APIBRASIL",
            auto_close: 120000
        }, {
            headers: {
                'authorization': `Bearer ${token}`,
                'DeviceToken': deviceToken
            }
        });

        const { status, data } = await apiBrasil.post('/whatsapp/qrcode', {}, {
            headers: {
                'authorization': `Bearer ${token}`,
                'DeviceToken': deviceToken
            }
        });

        return { status, data }
    }
}