import { format, parseISO } from 'date-fns';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCopy, FiPrinter } from 'react-icons/fi';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Box, Divider, Flex, Image, Text, useToast } from '@chakra-ui/core';

import logoColorful from '../../../../assets/logo-colorful.png';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';
import CurrencyUtils from '../../../../shared/utils/CurrencyUtils';
import DateUtils from '../../../../shared/utils/DateUtils';
import TextUtils from '../../../../shared/utils/TextUtils';
import PictureZoom from '../../components/PictureZoom';

const OrderDetailsPage: React.FC = () => {
    const { changeTitle } = useLayout();
    const { store } = useAuth();
    const printComponentRef = useRef();
    const [order, setOrder] = useState(null);
    const [products, setProducts] = useState([]);
    const { id } = useParams();
    const toast = useToast();

    const handleChangePrintStatus = useCallback(async () => {
        await apiGateway.put(`/orders/${id}/print_status`);
    }, [id]);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current,
        pageStyle: `
      *, h1, h2, h3, h4, h5, p, a {
        color: black!important;
      }
    `,
        onAfterPrint: () => handleChangePrintStatus()
    });

    const handleCopyMessage = useCallback(() => {
        if (!order) return;
        navigator.clipboard.writeText(order.details.card_message || '');
        toast({
            title: 'Mensagem Copiada',
            description: '',
            status: 'success',
            duration: 4000,
            isClosable: true
        });
    }, [order]);

    useEffect(() => {
        apiGateway.get(`/orders/${id}`).then(async (response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                changeTitle(`Pedido - ${response.data.code}`);
                setOrder(response.data);

                const orderProducts = response.data.products;

                const result = await Promise.all(orderProducts.map(async (product) => {
                    const { data: currentProduct } = await apiGateway.get(`/products/${product.id}`);

                    return {
                        ...product,
                        description: currentProduct.description || "",
                    }
                }));

                setProducts(result);
            }
        });
    }, [changeTitle, id]);

    return (
        <Flex width="100%" justifyContent="center">
            <Flex width="600px">
                {order && (
                    <Flex width="100%" flexDirection="column">
                        <Flex
                            ref={printComponentRef}
                            width="100%"
                            height="100%"
                            backgroundColor="white"
                            p="8px"
                        >
                            <Flex
                                flexWrap="wrap"
                                border="2px dashed"
                                borderColor="gray.700"
                                p="8px"
                            >
                                <Flex width="100%" justifyContent="space-between">
                                    <Flex width="70%" flexDirection="column" fontSize="14px">
                                        <Text as="h1" color="blue.700" fontSize="16px" mb="8px">
                                            {`Pedido - ${order.code}`}
                                        </Text>
                                        <Text />
                                        <Text>{`Forma de Pagamento: ${order.payment_method.name}`}</Text>
                                        <Text>
                                            {`Status de Pagamento: ${TextUtils.convertStatusPayment(
                                                order.payment_status
                                            )}`}
                                        </Text>
                                        {order.florist && (
                                            <Text>
                                                {`Florista: ${
                                                    order.florist.name || order.florist.email
                                                }`}
                                            </Text>
                                        )}
                                        {order.seller && (
                                            <Text>
                                                {`Vendedor: ${
                                                    order.seller.name || order.seller.email
                                                }`}
                                            </Text>
                                        )}
                                        {order.deliveryman && (
                                            <Text>{`Entregador: ${order.deliveryman.name}`}</Text>
                                        )}
                                    </Flex>

                                    <Flex
                                        width="30%"
                                        height="100%"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Box width="100%">
                                            <Image src={logoColorful} opacity={0.2} />
                                        </Box>
                                    </Flex>
                                </Flex>

                                <Divider width="100%" my="16px" />

                                <Flex width="100%" wrap="wrap">
                                    <Flex width="100%">
                                        <Text color="blue.700" fontSize="16px" mb="8px">
                                            Comprador
                                        </Text>
                                    </Flex>
                                    <Flex width="50%" flexDirection="column" fontSize="14px">
                                        <Text>{`Nome: ${order.buyer.name}`}</Text>
                                        <Text>
                                            {`CPF: ${TextUtils.maskCPF(
                                                order.buyer?.document || ''
                                            )}`}
                                        </Text>
                                    </Flex>

                                    <Flex width="50%" flexDirection="column" fontSize="14px">
                                        <Text>
                                            {`Telefone: ${TextUtils.mask(
                                                order.buyer.telephone,
                                                '+#############'
                                            )}`}
                                            <Text>{`E-mail: ${order.buyer.email}`}</Text>
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Divider width="100%" my="16px" />

                                <Flex width="100%">
                                    <Flex width="100%" flexDirection="column" fontSize="14px">
                                        <Text color="blue.700" fontSize="16px" mb="8px">
                                            Dados de Entrega
                                        </Text>

                                        <Flex flexDirection={'column'}>
                                            <Text>{`Cidade: ${
                                                order?.delivery_address?.city || 'Não Informado'
                                            }`}</Text>
                                            <Text>{`Bairro: ${
                                                order?.delivery_address?.neighborhood ||
                                                'Não Informado'
                                            }`}</Text>
                                            <Text>{`Rua: ${
                                                order?.delivery_address?.street || 'Não Informado'
                                            }, ${order?.delivery_address?.number || ''}`}</Text>
                                            <Text>
                                                {`Complemento: ${
                                                    order?.delivery_address?.complement
                                                        ? order.delivery_address.complement
                                                        : 'Não informado'
                                                }`}
                                            </Text>
                                        </Flex>

                                        <Flex mt="24px">
                                            <Text mr="8px">Data de Entrega: </Text>
                                            <Text>{DateUtils.format(order.delivery_date)}</Text>
                                        </Flex>

                                        <Flex>
                                            <Text mr="8px">Hora: </Text>
                                            <Text>{order.delivery_hour}</Text>
                                        </Flex>

                                        <Text
                                            color="black"
                                            fontSize="16px"
                                            mt="24px"
                                            whiteSpace="pre-wrap"
                                        >
                                            {`Obs: ${
                                                order.details.observations || 'Não informada'
                                            }`}
                                        </Text>
                                    </Flex>

                                    <Flex width="50%" flexDirection="column" fontSize="14px">
                                        <Text color="blue.700" fontSize="16px" mb="8px">
                                            Dados do Destinatário
                                        </Text>
                                        <Text>{`Nome: ${order.details.destinatary_name}`}</Text>
                                        {`Telefone: ${
                                            order.details.destinatary_telephone
                                                ? TextUtils.mask(
                                                      order.details.destinatary_telephone,
                                                      '+#############'
                                                  )
                                                : 'Não informado'
                                        }`}
                                        <Flex mt="24px">
                                            <QRCode
                                                size={80}
                                                style={{
                                                    height: 'auto',
                                                    maxWidth: '80%',
                                                    width: '80%'
                                                }}
                                                value={`https://dashboard.eflorista.com.br/delivery/${store}/${order.id}`}
                                                viewBox={'0 0 160 160'}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>

                                <Divider width="100%" my="16px" />

                                <Flex width="100%" flexDirection="column">
                                    <Flex
                                        width="100%"
                                        justifyContent="space-between"
                                        fontSize="14px"
                                    >
                                        <Flex width="100%" justifyContent="center" maxWidth="56px">
                                            <Text>Foto</Text>
                                        </Flex>
                                        <Flex width="100%" justifyContent="center">
                                            <Text>Nome</Text>
                                        </Flex>
                                        <Flex width="100%" justifyContent="center" maxWidth="80px">
                                            <Text>QTD</Text>
                                        </Flex>
                                        <Flex width="100%" justifyContent="center" maxWidth="104px">
                                            <Text>Total Item</Text>
                                        </Flex>
                                    </Flex>
                                    <Divider width="100%" my="4px" />

                                    {products.map((product) => (
                                        <Flex key={product.id} width="100%" flexDirection="column">
                                            <Flex
                                                width="100%"
                                                justifyContent="space-between"
                                                fontSize="12px"
                                                alignItems="center"
                                            >
                                                <Flex
                                                    width="100%"
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    maxWidth="56px"
                                                >
                                                    <PictureZoom
                                                        width="80px"
                                                        height="100%"
                                                        img={
                                                            product?.variant
                                                                ? product.variant.image_url
                                                                : product.cover.thumb_url
                                                        }
                                                    />
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    flexDirection="column"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                >
                                                    <Text>{product.name}</Text>
                                                    {product?.variant && (
                                                        <Text>{product.variant.name}</Text>
                                                    )}
                                                    <Text
                                                        className='no-print'
                                                        mt="4px"
                                                        dangerouslySetInnerHTML={{
                                                            __html: product.description
                                                        }}
                                                    />
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="center"
                                                    maxWidth="80px"
                                                    fontSize="16px"
                                                    fontWeight="600"
                                                >
                                                    <Text>{product.quantity}</Text>
                                                </Flex>

                                                <Flex
                                                    width="100%"
                                                    justifyContent="center"
                                                    maxWidth="104px"
                                                >
                                                    <Text>
                                                        {product?.variant &&
                                                            CurrencyUtils.numberToCurrency(
                                                                product.variant.price
                                                            )}
                                                        {product.is_promotional &&
                                                            !product?.variant &&
                                                            CurrencyUtils.numberToCurrency(
                                                                Number(product.price_promotional) *
                                                                    Number(product.quantity)
                                                            )}

                                                        {!product.is_promotional &&
                                                            !product?.variant &&
                                                            CurrencyUtils.numberToCurrency(
                                                                Number(product.price_sale) *
                                                                    Number(product.quantity)
                                                            )}
                                                    </Text>
                                                </Flex>
                                            </Flex>

                                            <Divider width="100%" my="2px" />
                                        </Flex>
                                    ))}
                                </Flex>

                                <Flex width="100%" mt="24px">
                                    <Flex width="100%" flexDirection="column">
                                        <Flex fontSize="14px" mt="24px">
                                            <Text mr="8px">De:</Text>
                                            <Text>
                                                {order.details.identify_name || 'Não Identificado'}
                                            </Text>
                                        </Flex>

                                        <Flex fontSize="14px">
                                            <Text mr="8px">Para:</Text>
                                            <Text>{order.details.destinatary_name}</Text>
                                        </Flex>

                                        <Flex color="purple.500" mt="8px">
                                            <Text
                                                color="blue.700"
                                                fontSize="16px"
                                                mb="8px"
                                                mr="8px"
                                            >
                                                Mensagem a ser impressa no cartão
                                            </Text>
                                            <FiCopy
                                                onClick={() => handleCopyMessage()}
                                                title="Copiar mensagem"
                                            />
                                        </Flex>

                                        <Text fontSize="14px" whiteSpace="pre-wrap">
                                            {order.details.card_message
                                                ? order.details.card_message
                                                : 'Não informada'}
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex
                                    width="100%"
                                    mt="24px"
                                    flexDirection="row"
                                    justifyContent="space-between"
                                >
                                    <Flex
                                        flexDirection="column"
                                        alignItems="center"
                                        fontSize="14px"
                                    >
                                        <Text>Total de Produtos:</Text>
                                        <Text>
                                            {CurrencyUtils.numberToCurrency(order.products_value)}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        flexDirection="column"
                                        alignItems="center"
                                        fontSize="14px"
                                    >
                                        <Text>Entrega:</Text>
                                        <Text>
                                            {CurrencyUtils.numberToCurrency(order.delivery_value)}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        flexDirection="column"
                                        alignItems="center"
                                        fontSize="14px"
                                    >
                                        <Text>Acréscimo:</Text>
                                        <Text>
                                            {CurrencyUtils.numberToCurrency(order.tax_value)}
                                        </Text>
                                    </Flex>

                                    <Flex
                                        flexDirection="column"
                                        alignItems="center"
                                        fontSize="14px"
                                    >
                                        <Text>Desconto:</Text>
                                        <Text>
                                            {`- ${CurrencyUtils.numberToCurrency(
                                                order.discount_value
                                            )}`}
                                        </Text>
                                    </Flex>
                                    <Flex
                                        flexDirection="column"
                                        alignItems="center"
                                        fontSize="14px"
                                    >
                                        <Text fontSize="16px" mr="16px">
                                            Total:
                                        </Text>
                                        <Text fontSize="18px">
                                            {CurrencyUtils.numberToCurrency(order.total_value)}
                                        </Text>
                                    </Flex>
                                </Flex>

                                <Flex width="100%" justifyContent="flex-start">
                                    <Flex
                                        alignItems="center"
                                        fontSize="12px"
                                        style={{ gap: '8px' }}
                                    >
                                        <Text>Entregador:</Text>
                                        <Text>
                                            {order.deliveryman
                                                ? order.deliveryman.name
                                                : 'Não Informado'}
                                        </Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>

                        {order && order.delivery_data.length > 0 && (
                            <Flex
                                width="100%"
                                backgroundColor="white"
                                mt="24px"
                                p="16px"
                                justifyContent="space-between"
                            >
                                <Flex width="48%" flexDirection="column" fontSize="14px">
                                    <Text fontWeight="600">{`Status da Entrega: ${order.delivery_status}`}</Text>
                                    <Text mt="8px">{`Data: ${format(
                                        parseISO(
                                            order.delivery_data[order.delivery_data.length - 1]
                                                .created_at
                                        ),
                                        'dd/MM/yy - HH:mm'
                                    )}`}</Text>
                                    <Text>{`Para: ${
                                        order.delivery_data[order.delivery_data.length - 1]
                                            .receiver_name
                                    }`}</Text>

                                    <Text>{`Obs: ${
                                        order.delivery_data[order.delivery_data.length - 1]
                                            .observations || 'Nenhuma'
                                    }`}</Text>
                                </Flex>

                                <Flex
                                    width="48%"
                                    flexDirection="column"
                                    fontSize="14px"
                                    justifyContent="flex-end"
                                >
                                    <Flex
                                        width="200px"
                                        height="96px"
                                        backgroundColor="gray.500"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        {order.delivery_data[order.delivery_data.length - 1]
                                            .photo_url !== '' ? (
                                            <Image
                                                height="100%"
                                                src={
                                                    order.delivery_data[
                                                        order.delivery_data.length - 1
                                                    ].photo_url
                                                }
                                            />
                                        ) : (
                                            <Text fontSize="14px">Sem foto</Text>
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}
                    </Flex>
                )}
            </Flex>

            <Flex flexDirection="column" alignItems="flex-end" ml="16px">
                <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={() => handlePrint()}
                    backgroundColor="purple.500"
                    color="white"
                    px="8px"
                    py="4px"
                >
                    <FiPrinter size={18} />
                    <Text ml="8px">Imprimir</Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

export default OrderDetailsPage;
