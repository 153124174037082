import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { Divider, Flex, Text, Checkbox, Badge } from '@chakra-ui/core';

import Button from '../../../../components/Button';
import List from '../../../../components/List';
import ListEmpty from '../../../../components/List/ListEmpty';
import ListRow from '../../../../components/List/ListRow';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';
import { HTTP } from '../../../../shared/constants';

const SetsPage: React.FC = () => {
    const navigate = useNavigate();
    const { changeTitle } = useLayout();
    const [selectedSetsIds, setSelectedSetsIds] = useState([]);
    const [sets, setSets] = useState([]);

    const ORDER_BY = {
        'alphabetical': 'Alfabética',
        'price_asc': 'Preço Crescente',
        'price_desc': 'Preço Decrescente'
    };

    const handleDeleteSets = useCallback(() => {
        apiGateway
            .delete('/settings/sets', {
                params: {
                    ids: selectedSetsIds
                }
            })
            .then(response => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    navigate(0);
                }
            });
    }, [selectedSetsIds]);

    const handleChangeSelectedSetsIds = useCallback(
        (input: any) => {
            let list = selectedSetsIds;

            if (input.checked) {
                if (!selectedSetsIds.includes(input.value as never)) {
                    list.push(input.value as never);
                }
            } else {
                list = list.filter(item => item !== input.value);
            }

            setSelectedSetsIds(list);
        },
        [selectedSetsIds]
    );

    const SetRow: React.FC<any> = ({ style, index, data }) => {
        return (
            <ListRow
                style={{
                    ...style
                }}
                index={index}
            >
                <Flex width="100%" justifyContent="center" maxWidth="40px">
                    <Checkbox
                        variantColor="green"
                        size="sm"
                        onChange={e => handleChangeSelectedSetsIds(e.currentTarget)}
                        value={data[index].id}
                        borderColor="gray.700"
                        style={{
                            outline: 'none'
                        }}
                    />
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Text>{data[index].title}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Text>{data[index].priority}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Text>{ORDER_BY[data[index].order_by]}</Text>
                </Flex>

                <Flex width="100%" justifyContent="center" maxWidth="144px">
                    <Badge
                        width="80px"
                        textAlign="center"
                        backgroundColor={data[index].is_enable ? 'green.500' : 'pink.500'}
                        color="white"
                        py="4px"
                        fontSize="10px"
                    >
                        {data[index].is_enable ? 'Ativo' : 'Inativo'}
                    </Badge>
                </Flex>
            </ListRow>
        );
    };

    useEffect(() => {
        changeTitle('Conjuntos');
        apiGateway.get('/settings/sets').then(response => {
            const { status } = response;
            if(status === HTTP.STATUS.SUCCESS)
                setSets(response.data);
        });
    }, [changeTitle]);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" mb="8px" alignItems="center">
                <Button
                    backgroundColor="purple.500"
                    width="200px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    onClick={() => navigate('/settings/sets/register')}
                >
                    Novo Conjunto
                </Button>

                <Button
                    backgroundColor="pink.500"
                    width="56px"
                    p="0px"
                    fontSize="16px"
                    height="40px"
                    mr="16px"
                    title="Excluir Conjuntos"
                    _hover={{
                        backgroundColor: 'pink.500'
                    }}
                    onClick={() => handleDeleteSets()}
                >
                    <FiTrash2 size={20} />
                </Button>
            </Flex>

            <Flex width="100%" flexDirection="column">
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                >
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        flexWrap="wrap"
                    >
                        <Flex width="50%" justifyContent="flex-start" alignItems="center">
                            <Text mr="16px" fontSize="24px" color="purple.500">
                                Listagem
                            </Text>
                        </Flex>
                    </Flex>

                    <Divider my="0px" />
                    <Flex
                        width="100%"
                        py="8px"
                        justifyContent="space-between"
                        fontSize="14px"
                        maxWidth="100%"
                    >
                        <Flex width="100%" justifyContent="center" maxWidth="40px">
                            <Text>#</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="144px">
                            <Text>Conjunto</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="144px">
                            <Text>Prioridade</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="144px">
                            <Text>Ordem</Text>
                        </Flex>

                        <Flex width="100%" justifyContent="center" maxWidth="144px">
                            <Text>Status</Text>
                        </Flex>
                    </Flex>
                    <Divider my="0px" />

                    {sets.length > 0 && (
                        <List item={sets} row={SetRow} itemHeight={48} />
                    )}

                    {sets.length <= 0 && <ListEmpty />}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default SetsPage;
