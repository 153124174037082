import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import TextArea from '../../../../components/Form/TextArea';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';
import UploadLogoImage from '../GeneralSettings/components/UploadLogoImage';

type SetProps = {
    id: string;
    title: string;
    list: string[];
    order_by: 'alphabetical' | 'popularity' | 'price_asc' | 'price_desc';
    priority: number;
    is_enable: boolean;
};

const ServicesRegister: React.FC = () => {
    const formRef = useRef<FormHandles>();
    const navigate = useNavigate();
    const toast = useToast();

    const [uploadLogo, setUploadLogo] = useState('');

    const handleUploadLogo = useCallback((url: string) => {
        setUploadLogo(url);
    }, []);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.post(
                    '/settings/set-services',
                    {
                        name: formData.name,
                        title: formData.title,
                        url_image: uploadLogo,
                        description: formData.description,
                        link: formData.link
                    }
                );
                if (response.status === HTTP.STATUS.CREATED) {
                    FormHelper.onSuccess(
                        'Cadastro Realizado',
                        'Serviço cadastrado com sucesso',
                        toast
                    );
                    FormHelper.reset(formRef as any);
                    navigate('/settings/services');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [history, toast, uploadLogo]
    );

    return (
        <Form
            ref={formRef as any}
            onSubmit={handleSubmit}
            placeholder=""
            autoComplete="off"
            style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center'
            }}
        >
            <Flex
                width="100%"
                maxWidth="800px"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Registrar novo serviço
                </Text>

                <Flex width="100%" flexDirection="column">
                    <Input name="name" label="Nome do Serviço" isRequired />

                    <Flex width="100%" flexDirection="column" mb="4">
                        <label>Imagem</label>
                        <UploadLogoImage
                            onUploadFunction={handleUploadLogo}
                            defaultImage={uploadLogo}
                        />
                    </Flex>

                    <Input name="title" label="Título" />
                    <TextArea name="description" label="Descrição" />
                    <Input name="link" label="Link" isRequired />
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default ServicesRegister;
