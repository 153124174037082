import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Divider, Flex, Icon, Text } from '@chakra-ui/core';
import { useLayout } from '../../../../layouts/default';
import apiGateway from '../../../../shared/services/apiGateway';
import { HTTP } from '../../../../shared/constants';
import OrderProductionDetails from './OrderDetails';
import PanelButton from '../../../../components/PanelButton';
import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import { FormHandles } from '@unform/core';
import Form from '../../../../components/Form';
import { FiSearch } from 'react-icons/fi';

const OrdersProduction: React.FC = () => {
    const { changeTitle } = useLayout();
    const formRef = useRef<FormHandles>();
    const [orders, setOrders] = useState([]);
    const [deliveriesMoment, setDeliveriesMoment] = useState('TODAY');

    const ordersMorning = useMemo(() => {
        return orders.filter((order) => order.delivery_hour === 'Manhã' || order.delivery_hour === 'Manha');
    }, [orders]);

    const ordersEvening = useMemo(() => {
        return orders.filter((order) => order.delivery_hour === 'Tarde');
    }, [orders]);

    const ordersNight = useMemo(() => {
        return orders.filter((order) => order.delivery_hour === 'Noite');
    }, [orders]);

    const loadData = useCallback(() => {
        apiGateway.get(`/orders/production?moment=${deliveriesMoment}`)
            .then((response) => {
                const { status, data } = response;
                if(status === HTTP.STATUS.SUCCESS)
                    setOrders(data);
            });
    }, [deliveriesMoment]);

    const showDeliveries = useCallback(moment => {
        apiGateway.get(`/orders/production?moment=${moment}`).then(response => {
            const { status, data } = response;
            if (status === HTTP.STATUS.SUCCESS) {
                setOrders(data);
            }
        });
  
        setDeliveriesMoment(moment);
    }, []);

    const handleSearch = useCallback(() => {
        const value = formRef.current.getFieldValue('query');
        if(value) {
            apiGateway.get(`/orders/production?query=${value}`).then(response => {
                const { status, data } = response;
                if (status === HTTP.STATUS.SUCCESS) {
                    setOrders(data);
                    setDeliveriesMoment(null);
                }
            }); 
        } else {
            apiGateway.get('/orders/production?moment=TODAY')
                .then((response) => {
                    const { status, data } = response;
                    if(status === HTTP.STATUS.SUCCESS)
                        setOrders(data);
                });
            setDeliveriesMoment('TODAY');
        }    
    }, []);

    const DAYS = {
        DELAYED: ' Atrasados',
        TODAY: ' de Hoje',
        TOMORROW: ' de Amanhã',
        FUTURE: ' Futuras'
    };

    useEffect(() => {
        changeTitle('Pedidos para Produção');
        loadData();
        const interval = setInterval(() => {
            loadData();
        },  3600000);

        return () => clearInterval(interval);
    }, []);

    return(
        <Flex width="100%" 
            height="100%" 
            flexDirection="column" 
            backgroundColor="white"
            padding="24px"
        >
            <Flex width="100%" py="8px" justifyContent="space-between">
                <Form
                    ref={formRef as any}
                    style={{
                        display: 'flex'
                    }}
                    onSubmit={() => null}
                >
                    <Flex justifyContent="flex-start" alignItems="center">
                        <Text
                            mr="48px"
                            fontSize="18px"
                            color="purple.500"
                            whiteSpace="nowrap"
                        >
                  Pedidos
                            {DAYS[deliveriesMoment]}
                        </Text>
  
                        <Flex alignItems="center">
                            <Input
                                width="400px"
                                name="query"
                                placeholder="Pesquisar por código"
                                size="sm"
                                mb="0px"
                                onEnterPress={() => handleSearch()}
                            />
  
                            <Button
                                height="32px"
                                width="40px"
                                borderRadius="2px"
                                ml="8px"
                                backgroundColor="green.500"
                                cursor="pointer"
                                onClick={() => handleSearch()}
                            >
                                <Icon as={FiSearch} size="20px" />
                            </Button>
                        </Flex>
                    </Flex>
                </Form>
                <Flex width="50%" justifyContent="flex-end" alignItems="center">
                    <Text mr="16px" fontSize="12px">
                Mostrar:
                    </Text>
                    <PanelButton
                        text="Hoje"
                        isSelected={deliveriesMoment === 'TODAY'}
                        buttonFunction={() => showDeliveries('TODAY')}
                    />
                    <PanelButton
                        text="Amanhã"
                        isSelected={deliveriesMoment === 'TOMORROW'}
                        buttonFunction={() => showDeliveries('TOMORROW')}
                    />
                    <PanelButton
                        mr="0px"
                        text="Futuras"
                        isSelected={deliveriesMoment === 'FUTURE'}
                        buttonFunction={() => showDeliveries('FUTURE')}
                    />
                </Flex>
            </Flex>
                
            <Flex width="100%" height="32px" px="8px" my="16px" alignItems="center" backgroundColor="purple.500">
                <Text color="white">Pedidos da Manhã</Text>
            </Flex>

            {ordersMorning.map((order) => ( 
                <Flex key={order.id} width="100%" flexDirection="column">
                    <OrderProductionDetails  order={order}  />
                    <Divider borderColor="gray.700" my="4" />
                </Flex>
            ))}

            {ordersMorning.length <= 0 && (
                <Flex width="100%" mt="8px">
                    <Text color="gray.400">Nenhum pedido encontrado no turno da manhã</Text>
                </Flex>
            )}

            <Flex width="100%" height="32px" px="8px" my="16px" alignItems="center" backgroundColor="purple.500">
                <Text color="white">Pedidos da Tarde</Text>
            </Flex>

            {ordersEvening.map((order) => ( 
                <Flex key={order.id} width="100%" flexDirection="column">
                    <OrderProductionDetails  order={order}  />
                    <Divider borderColor="gray.700" my="4" />
                </Flex>
            ))}

            {ordersEvening.length <= 0 && (
                <Flex width="100%" mt="8px">
                    <Text color="gray.400">Nenhum pedido encontrado no turno da tarde</Text>
                </Flex>
            )}

            <Flex width="100%" height="32px" px="8px" my="16px" alignItems="center" backgroundColor="purple.500">
                <Text color="white">Pedidos da Noite</Text>
            </Flex>

            {ordersNight.map((order) => ( 
                <Flex key={order.id} width="100%" flexDirection="column">
                    <OrderProductionDetails  order={order}  />
                    <Divider borderColor="gray.700" my="4" />
                </Flex>
            ))}
            
            {ordersNight.length <= 0 && (
                <Flex width="100%" mt="8px">
                    <Text color="gray.400">Nenhum pedido encontrado no turno da noite</Text>
                </Flex>
            )}
        </Flex>
    );
};

export default OrdersProduction;
