import 'react-day-picker/lib/style.css';

import React, { useEffect } from 'react';

import LayoutDefault from '../layouts/default';
import ScrollToTop from '../shared/components/ScrollToTop';
import { useAuth } from '../shared/hooks/useAuth';
import apiGateway from '../shared/services/apiGateway';

interface IRouteProps {
    withoutLayout?: boolean;
    component: React.ComponentType;
    isPrivate?: boolean;
}

const Route: React.FC<IRouteProps> = ({
    withoutLayout,
    component: Component,
}) => {
    const { setSession } = useAuth();

    useEffect(() => {
        const token = localStorage.getItem('@eflorista:token');
        const user = localStorage.getItem('@eflorista:user');
        const branch = localStorage.getItem('@eflorista:branch');
        const store = localStorage.getItem('@eflorista:store');
        const store_name = localStorage.getItem('@eflorista:store_name');

        if (token && user) {
            apiGateway.defaults.headers.authorization = `Bearer ${token}`;
            setSession({ token, user: JSON.parse(user), branch, store, store_name });
        }
    }, []);


    return !withoutLayout 
        ? (
            <LayoutDefault>
                <ScrollToTop />
                <Component />
            </LayoutDefault>
        ) : (
            <Component />
        );
};

export default Route;
