import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiPrinter } from 'react-icons/fi';

import { Flex, Image, Link, Text } from '@chakra-ui/core';

import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import template from '../../../../assets/images/abaesco-pngtransp.png';
import Form from '../../../../components/Form';
import TextEditor from '../../../../components/Form/TextEditor';
import { useLayout } from '../../../../layouts/default';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const OrderCardEdit: React.FC = () => {
    const { id } = useParams();
    const formRef = useRef();
    const printRef = useRef();

    const { changeTitle } = useLayout();

    const [order, setOrder] = useState(null);
    const [generalSetting, setGeneralSetting] = useState(null);
    const [message, setMessage] = useState('');

    const templateMessage = (order) => {
        return `
            Para: ${order.details.destinatary_name}
            <br>
            <br>
            <br>
            <br>
            ${order.details.card_message}
            ${
                order.details.identify_name
                    ? `
                <br>
                <br>
                <br>
                <br>
                <br>
                <br>
            ${order.details.identify_name}
            `
                    : ''
            }
        `;
    };

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        onAfterPrint: () => handleChangePrintStatus()
    });

    const handleChangePrintStatus = useCallback(async () => {
        await apiGateway.put(`/orders/${id}/card/print`);
    }, [id]);

    useEffect(() => {
        apiGateway.get(`/orders/${id}`).then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                changeTitle(`Pedido - ${response.data.code}`);
                setOrder(response.data);
                setMessage(templateMessage(response.data));
            }
        });

        apiGateway.get('/settings/general_settings').then((response) => {
            setGeneralSetting(response.data);
        });
    }, [changeTitle, id]);

    return (
        order &&
        generalSetting && (
            <Form
                ref={formRef}
                onSubmit={() => null}
                autoComplete="off"
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                {(!generalSetting.url_logo || !generalSetting.domain) && (
                    <Flex
                        py="4px"
                        px="8px"
                        border="1px solid"
                        borderColor="red.500"
                        backgroundColor="red.100"
                        marginBottom="24px"
                    >
                        <Link href="/settings/general">
                            <Text>
                                Clique aqui para adicionar o logo da sua loja e seu dominio para
                                customizar o seu cartão
                            </Text>
                        </Link>
                    </Flex>
                )}
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'center'
                    }}
                >
                    <Flex width="100%" flexDir="column" alignItems="center" backgroundColor="white">
                        <Flex
                            ref={printRef}
                            width="100%"
                            minHeight="100%"
                            maxHeight="1024px"
                            position="relative"
                            p="48px"
                        >
                            <Image
                                src={template}
                                position="absolute"
                                left="0"
                                top="0"
                                zIndex={10}
                            />
                            <Flex height="100%" direction="column" flex="1" alignItems="center">
                                <img
                                    src={generalSetting.url_logo}
                                    width="200px"
                                    height="100px"
                                    style={{
                                        width: '200px',
                                        height: '100px'
                                    }}
                                />

                                <h1
                                    style={{
                                        fontSize: '20px',
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        fontWeight: 'bold',
                                        fontFamily: 'DM Serif Display'
                                    }}
                                >
                                    Mensagem para você
                                </h1>

                                <TextEditor
                                    name="message"
                                    width={480}
                                    defaultValue={templateMessage(order)}
                                    onChange={(e) => setMessage(e)}
                                />

                                <div
                                    className="onlyPrint"
                                    dangerouslySetInnerHTML={{
                                        __html: message
                                    }}
                                    style={{
                                        backgroundColor: 'transparent',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        wordWrap: 'break-word',
                                        width: '480px',
                                        minHeight: '600px',
                                        maxHeight: '680px',
                                        border: '1px solid gray',
                                        padding: '24px',
                                        borderRadius: '8px',
                                        zIndex: 100
                                    }}
                                />

                                <div
                                    style={{
                                        marginTop: '16px',
                                        display: 'flex',
                                        gap: '24px'
                                    }}
                                >
                                    <QRCode
                                        size={80}
                                        style={{
                                            height: 'auto',
                                            maxWidth: '80px',
                                            width: '80px'
                                        }}
                                        value={generalSetting.domain}
                                        viewBox={'0 0 160 160'}
                                    />

                                    <Flex flexDirection="column" alignItems="center">
                                        <span>{generalSetting.name}</span>
                                        <span>{generalSetting.domain}</span>
                                        <span>{generalSetting.whatsapp}</span>
                                    </Flex>
                                </div>
                            </Flex>
                            <Image
                                src={template}
                                position="absolute"
                                right="0"
                                bottom="0"
                                transform="rotate(-180deg)"
                                zIndex={10}
                            />
                        </Flex>
                    </Flex>

                    <Flex flexDirection="column" alignItems="flex-end" ml="16px">
                        <Flex
                            alignItems="center"
                            cursor="pointer"
                            onClick={() => handlePrint()}
                            backgroundColor="purple.500"
                            color="white"
                            px="8px"
                            py="4px"
                        >
                            <FiPrinter size={18} />
                            <Text ml="8px">Imprimir</Text>
                        </Flex>
                    </Flex>
                </div>
            </Form>
        )
    );
};

export default OrderCardEdit;
