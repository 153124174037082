import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Flex, Box, Image, Heading, Text, PseudoBox, useToast } from '@chakra-ui/core';
import { Form } from '@unform/web';

import logo from '../../../../assets/logo-white.png';
import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import Loader from '../../../../shared/components/Loader';
import { useAuth } from '../../../../shared/hooks/useAuth';
import { FormHandles } from '@unform/core';

type DataSubmit = {
  email: string;
  password: string;
};

const SignIn: React.FC = () => {
    const navigate = useNavigate();
    const { signIn } = useAuth();

    const formRef = useRef<FormHandles>();
    const [loading, setLoading] = useState(false);

    const handleToForgotPassword = useCallback(() => {
        navigate('/forgot-password');
    }, [navigate]);

    const toast = useToast();

    const handleSubmitLogin = useCallback(
        async (dataSubmit: DataSubmit) => {
            setLoading(true);
            try {
                const { email, password } = dataSubmit;
                if(email && password) {
                    const isAuthenticate = await signIn({ email,  password });
                    if(isAuthenticate)
                        navigate('/dashboard');                                             
                } else {
                    toast({
                        title: 'Campos obrigatório',
                        description: 'Informe o email e senha ',
                        status: 'error',
                        duration: 4000,
                        isClosable: true
                    });
                }
                setLoading(false);
            } catch (err) {
                setLoading(false);
                toast({
                    title: 'Usuario não encontrado',
                    description: 'Email ou senha incorretos',
                    status: 'error',
                    duration: 4000,
                    isClosable: true
                });  
            }
        },
        [navigate, signIn]
    );

    return (
        <Flex width="100%" height="100vh">
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                backgroundColor="purple.500"
                height="100%"
            >
                <Box px="160px">
                    <Image src={logo} alt="Logo E-Florista" />
                </Box>
            </Flex>
            <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                height="100%"
            >
                <Form
                    ref={formRef}
                    onSubmit={handleSubmitLogin}
                    style={{
                        width: '100%'
                    }}
                >
                    <Flex
                        width="100%"
                        flexDirection="column"
                        p="98px"
                        fontWeight="500"
                        color="purple.500"
                    >
                        <Heading fontSize="24px" textAlign="center">
              Faça seu login
                        </Heading>

                        <Input name="email" size="md" placeholder="Seu email" mt="24px" />
                        <Input
                            name="password"
                            typeInput="password"
                            size="md"
                            placeholder="Sua senha"
                        />

                        <Flex justifyContent="space-between">
                            <Box width="50%">
                                <Checkbox
                                    name="remember_password"
                                    size="sm"
                                    variantColor="pink"
                                >
                  Lembrar senha
                                </Checkbox>

                                <PseudoBox
                                    onClick={() => handleToForgotPassword()}
                                    _hover={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Text fontSize="12px" color="gray.600">
                    Esqueci minha senha
                                    </Text>
                                </PseudoBox>
                            </Box>

                            <Flex width="50%" justifyContent="flex-end">
                                <Button
                                    type="button"
                                    width="100%"
                                    maxWidth="120px"
                                    fontSize="16px"
                                    py="8px"
                                    backgroundColor="purple.500"
                                    _hover={{
                                        backgroundColor: 'green.500'
                                    }}
                                    onClick={() => formRef.current.submitForm()}
                                >
                                    Entrar
                                </Button>
                            </Flex>
                        </Flex>
                    </Flex>
                </Form>
            </Flex>
            {loading && <Loader />}
        </Flex>
    );
};

export default SignIn;
