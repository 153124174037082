import { Divider, Flex, Icon, Image, Stack, Text, useDisclosure, useToast } from '@chakra-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { MdDoubleArrow } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { HTTP } from '../../../../../shared/constants';
import apiGateway from '../../../../../shared/services/apiGateway';
import ModalDeliverymen from '../ModalDeliverymen';
import ModalFlorists from '../ModalFlorists';

interface Props {
    order: any;
}

export default function OrderProductionDetails({ order }: Props) {
    const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
    const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();

    const [products, setProducts] = useState([]);

    const [step, setStep] = useState(() => order.delivery_status);
    const toast = useToast();
    const navigate = useNavigate();
    const handleChangeStep = useCallback(async () => {
        let status;
        setStep((oldState: string) => {
            status = oldState;
            if(oldState === 'AGUARDANDO_PRODUCAO') {
                onOpen1();
                status = 'EM_PRODUCAO';
            }

            if(oldState === 'EM_PRODUCAO') {
                status = 'PRODUZIDO';
            }

            if(oldState === 'PRODUZIDO') {
                onOpen2();
                status = 'SAIU_PARA_ENTREGA';
            }

            if(oldState === 'SAIU_PARA_ENTREGA') {
                status = 'ENTREGUE';
            }

            return status;
        });

        await apiGateway.put(`/orders/${order.id}/delivery_status`,{
            status
        }).then((response) => {
            const { status: resStatus } = response;

            if(resStatus === HTTP.STATUS.SUCCESS && status === 'ENTREGUE') {
                toast({
                    title: 'Pedido entregue',
                    description: 'Pedido entregue com sucesso',
                    status: 'success',
                    duration: 4000,
                    isClosable: true
                });
            }
        });

    }, [order]);

    useEffect(() => {
        if(order)
            setProducts(order.products);
    }, []);

    return (
        <Flex width="100%" flexDirection="column">
            <Flex width="100%" justifyContent="flex-end">
                <Flex width="100%" justifyContent="space-between">
                    <Flex alignItems="center" style={{ gap: '16px' }}>
                        <Text fontWeight="500" color="green.500">{`Pedido ${order.code}`}</Text>
                        <Flex flexDirection="column">
                            {order.details.identify_sender ? order.buyer.name : 'Anônimo'}
                        </Flex>
                    </Flex>
                    <Stack flex="1" direction="row" alignItems="center" justifyContent="flex-end" spacing="4" fontSize="14px">
                        <Text
                            backgroundColor={step === 'AGUARDANDO_PRODUCAO'  ? 'green.500' : 'none'}
                            color={step === 'AGUARDANDO_PRODUCAO' ? 'white' : 'gray.300'}
                            px="16px"
                            borderRadius="2px"
                        >
                            {step === 'AGUARDANDO_PRODUCAO' ? 'AGUARDANDO PRODUÇÃO' : 'Aguardando Produção'}
                        </Text>
                        <Text>|</Text>
                        <Text
                            backgroundColor={step === 'EM_PRODUCAO'  ? 'green.500' : 'none'}
                            color={step === 'EM_PRODUCAO' ? 'white' : 'gray.300'}
                            px="16px"
                            borderRadius="2px"
                        >
                            {step === 'EM_PRODUCAO' ? 'EM PRODUÇÃO' : 'Em produção'}
                        </Text>
                        <Text>|</Text>
                        <Text
                            backgroundColor={step === 'PRODUZIDO'  ? 'green.500' : 'none'}
                            color={step === 'PRODUZIDO' ? 'white' : 'gray.300'}
                            px="16px"
                            borderRadius="2px"
                        >
                            {step === 'PRODUZIDO' ? 'PRODUZIDO' : 'Produzido'}
                        </Text>
                        <Text>|</Text>
                        <Text
                            backgroundColor={step === 'SAIU_PARA_ENTREGA'  ? 'green.500' : 'none'}
                            color={step === 'SAIU_PARA_ENTREGA' ? 'white' : 'gray.300'}
                            px="16px"
                            borderRadius="2px"
                        >
                            {step === 'SAIU_PARA_ENTREGA' ? 'SAIU PARA ENTREGA' : 'Saiu para entrega'}
                        </Text>
                        <Text>|</Text>
                        <Text
                            backgroundColor={step === 'ENTREGUE'  ? 'green.500' : 'none'}
                            color={step === 'ENTREGUE' ? 'white' : 'gray.300'}
                            px="16px"
                            borderRadius="2px"
                        >
                            {step === 'ENTREGUE' ? 'ENTREGUE' : 'Entregue'}
                        </Text>

                        {step !== 'ENTREGUE' && (
                            <Flex
                                p="2px"
                                backgroundColor="red.500"
                                borderRadius="2px"
                                cursor="pointer"
                                onClick={() => handleChangeStep()}
                                title="Avançar Etapa"
                            >
                                <Icon as={MdDoubleArrow} color="white" width="28px" fontSize="20px" />
                            </Flex>
                        )}
                    </Stack>
                </Flex>
            </Flex>
            <Divider />

            <Flex width="100%" flexDirection="column">
                <Flex width="100%" height="auto" overflow="hidden" flexDirection="column">
                    {products.map((product, index) => (
                        <Flex key={product.id} width="100%" mb="24px">
                            <Flex width="360px" flexDirection="column" justifyContent="center" alignItems="center">
                                <Flex width="100%" flexDirection="column" style={{ gap: '4px' }}>
                                    <Text color="red.500" fontSize="24px">{`${product.quantity}x`}</Text>
                                    <Text color="gray.700" fontSize="16px">{product.name}</Text>
                                </Flex>

                                <Image src={product.cover.url} width={index === 0 ?  '100%' : '64%'} />
                            </Flex>

                            <Flex width="calc(100% - 360px)" flexDirection="column" px="24px">
                                <Flex width="100%" fontSize="14px" mt="24px" flexDirection="column">
                                    <Text fontWeight="500" fontSize="16PX" color="green.500">Composição</Text>

                                    <Flex key={product.id} width="100%" flexDirection="column">
                                        <Text  fontWeight="500" fontSize="16PX">
                                            {`Cód: ${product.code_sku} - ${product.name}`}
                                        </Text>
                                        <Flex width="100%" whiteSpace="pre-wrap">
                                            <Text
                                                dangerouslySetInnerHTML={{
                                                    __html: product.description
                                                }}
                                            />
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                    ))}

                    <Flex ml="384px"  width="100%" fontSize="14px" mt="auto" mb="24px" flexDirection="column">
                        <Text fontWeight="500" fontSize="16PX">Mensagem:</Text>
                        <Flex width="100%" whiteSpace="pre-wrap">
                            {order.details.card_message || 'Nenhuma mensagem'}
                        </Flex>
                    </Flex>

                    <Flex ml="384px"  width="100%" fontSize="14px" mt="auto" mb="24px" flexDirection="column">
                        <Text fontWeight="500" fontSize="16PX">OBSERVAÇÕES:</Text>
                        <Flex width="100%" whiteSpace="pre-wrap">
                            {order.details.observations || 'Nenhuma observação'}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
            <ModalFlorists isOpen={isOpen1} onClose={onClose1} orderId={order.id} />
            <ModalDeliverymen isOpen={isOpen2} onClose={onClose2} orderId={order.id} />
        </Flex>
    );
}