import axios from 'axios';

import { HTTP } from '../constants';

const apiGateway = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    validateStatus: (status: number) => {
        return status >= 200 && status <= 400;
    }
});

apiGateway.interceptors.request.use(
    (req) => {
        const token = localStorage.getItem('@eflorista:token');
        if (token) req.headers['Authorization'] = `Bearer ${token}`;
        return req;
    },
    (error) => Promise.reject(error)
);

apiGateway.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === HTTP.STATUS.NOT_AUTORIZED) {
            localStorage.removeItem('@eflorista:token');
            localStorage.removeItem('@eflorista:user');
            window.location.href = '/login';
        }

        return error;
    }
);

export const apiEflorista = (storeId: string) => {
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        validateStatus: (status: number) => {
            return status >= 200 && status <= 400;
        }
    });

    api.interceptors.request.use(
        (req) => {
            if (storeId) req.headers['store_id'] = storeId;
            return req;
        },
        (error) => Promise.reject(error)
    );

    return api;
};

export default apiGateway;
