import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import { HTTP } from '../../../../shared/constants';
import apiGateway from '../../../../shared/services/apiGateway';

const MakeBasketRegister: React.FC = () => {
    const [isRequired, setIsRequired] = useState(false);
    const [isEnable, setIsEnable] = useState(false);

    const formRef = useRef<FormHandles>();
    const toast = useToast();
    const navigate = useNavigate();

    const handleSubmit = useCallback(
        async (formData: any) => {
            apiGateway
                .post('/products/subtypes', {
                    ...formData,
                    is_enable: isEnable,
                    is_required: isRequired
                })
                .then((response) => {
                    if (response.status === HTTP.STATUS.CREATED) {
                        toast({
                            title: 'Cadastro efetuado',
                            description: 'Registrado com succeso',
                            status: 'success',
                            duration: 4000,
                            isClosable: true
                        });

                        navigate('/settings/make_basket');
                    }
                });
        },
        [navigate, isEnable, isRequired, toast]
    );

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            <Flex
                width="100%"
                maxWidth="800px"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontSize="14px"
            >
                <Text color="purple.500" fontSize="20px" mt="16px">
                    Informações Básicas
                </Text>

                <Flex minWidth="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                    <Box width="48%">
                        <Input name="value" label="Nome da Categoria" isRequired size="sm" />
                    </Box>

                    <Flex width="100%">
                        <Box fontWeight="500" mr="48px">
                            <Text>Obrigatória?</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_required"
                                    isChecked={isRequired === false}
                                    onChange={() => setIsRequired(!isRequired)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_required"
                                    isChecked={isRequired}
                                    onChange={() => setIsRequired(!isRequired)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>

                        <Box fontWeight="500" mr="48px">
                            <Text>Ativar Categoria?</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable === false}
                                    onChange={() => setIsEnable(!isEnable)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable}
                                    onChange={() => setIsEnable(!isEnable)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>
                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    Cadastrar
                </Button>
            </Flex>
        </Form>
    );
};

export default MakeBasketRegister;
