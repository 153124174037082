import React, { createRef, useCallback, useEffect, useRef, useState } from 'react';
import { BiCheckDouble } from 'react-icons/bi';
import {
    FiCalendar,
    FiCheck,
    FiDollarSign,
    FiEye,
    FiFile,
    FiPercent,
    FiPrinter,
    FiSearch,
    FiTruck
} from 'react-icons/fi';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';

import { Divider, Flex, Icon, PseudoBox, Text } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import { MdOutlineEmail } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ConfirmationPanel from '../../components/ConfirmationPanel';
import DeliveryStatus from '../../components/DeliveryStatus';
import Form from '../../components/Form';
import Input from '../../components/Form/Input';
import PanelButton from '../../components/PanelButton';
import PaymentStatus from '../../components/PaymentStatus';
import { useLayout } from '../../layouts/default';
import OrderPrint from '../../modules/order/components/OrderPrint';
import { HTTP } from '../../shared/constants';
import { useAuth } from '../../shared/hooks/useAuth';
import apiGateway from '../../shared/services/apiGateway';
import CurrencyUtils from '../../shared/utils/CurrencyUtils';
import DateUtils from '../../shared/utils/DateUtils';

interface ITTA {
    total_today: string;
    total_last_30_days: string;
    avg_ticket: string;
}

const Dashboard: React.FC = () => {
    const { changeTitle } = useLayout();

    const [ordersDelivery, setOrdesDelivery] = useState([]);
    const [ordersTomorrow, setOrdesTomorrow] = useState([]);
    const [payInStoreOrders, setPayInStoreOrders] = useState([]);

    const [printRefs, setPrintRefs] = useState<React.MutableRefObject<any>[]>([]);

    const { user } = useAuth();

    const [tta, setTTA] = useState({
        total_today: '0',
        total_last_30_days: '0',
        avg_ticket: '0'
    } as ITTA);

    const [deliveriesMoment, setDeliveriesMoment] = useState('TODAY');
    const [payInStoreMoment, setPayInStoreMoment] = useState('TODAY');
    const formRef = useRef<FormHandles>(null);

    const [confirmationData, setConfirmationData] = useState({
        code: null,
        showConfirmation: false,
        confirmationFunction: () => null,
        cancelFunction: () => null
    });

    const navigate = useNavigate();

    const DAYS = {
        DELAYED: ' Atrasadas',
        TODAY: ' de Hoje',
        TOMORROW: ' de Amanhã',
        FUTURE: ' Futuras'
    };

    const showDeliveries = useCallback((moment) => {
        apiGateway.get(`/orders/delivery?status=${moment}`).then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrdesDelivery(response.data);

                const refs = [];

                response.data.map((order) => {
                    refs[order.id] = createRef();
                    return refs;
                });

                setPrintRefs(refs);
            }
        });

        setDeliveriesMoment(moment);
    }, []);

    const showPayInStore = useCallback((moment) => {
        apiGateway.get(`/orders/delivery?pay_in_store=true&status=${moment}`).then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setPayInStoreOrders(response.data);

                const refs = [];

                response.data.map((order) => {
                    refs[order.id] = createRef();
                    return refs;
                });

                setPrintRefs(refs);
            }
        });

        setPayInStoreMoment(moment);
    }, []);

    const handleViewOrderDetails = useCallback(
        (id) => {
            navigate(`/orders/${id}`);
        },
        [history]
    );

    const handleViewOrderVoucher = useCallback(
        (id) => {
            navigate(`/orders/${id}/voucher`);
        },
        [history]
    );

    const handleChangePaymentStatus = useCallback(async (orderID: string, status: string) => {
        await apiGateway.put(`/orders/${orderID}/payment_status`, {
            status
        });
    }, []);

    const handleSearchDelivery = useCallback(() => {
        const query = formRef.current.getFieldValue('query');

        if (!!query !== false && query !== '') {
            apiGateway
                .get(`/orders/delivery?status=${deliveriesMoment}&query=${query}`)
                .then((response) => {
                    if (response.status === HTTP.STATUS.SUCCESS) {
                        setOrdesDelivery(response.data);

                        const refs = [];

                        response.data.map((order) => {
                            refs[order.id] = createRef();
                            return refs;
                        });

                        setPrintRefs(refs);
                    }
                });
        } else {
            showDeliveries(deliveriesMoment);
        }
    }, [deliveriesMoment, showDeliveries]);

    const loadData = useCallback(async () => {
        const refs = [];
        await apiGateway.get('/orders/delivery?status=TODAY').then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrdesDelivery(response.data);
                setDeliveriesMoment('TODAY');
                response.data.map((order) => {
                    refs[order.id] = createRef();
                    return refs;
                });
            }
        });

        await apiGateway.get('/orders/delivery?pay_in_store=true&status=TODAY').then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                response.data.map((order) => {
                    refs[order.id] = createRef();
                    return refs;
                });
                setPayInStoreOrders(response.data);
            }
        });

        await apiGateway.get('/relatories/TTA').then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setTTA(response.data);
            }
        });

        setPrintRefs(refs);
    }, []);

    const handleChangeDeliveryStatus = useCallback(
        async (code: string, orderID: string, status: string) => {
            setConfirmationData({
                code,
                showConfirmation: true,
                confirmationFunction: () => {
                    apiGateway
                        .put(`/orders/${orderID}/delivery_status`, {
                            status
                        })
                        .then(() => {
                            loadData();

                            setConfirmationData((oldState) => {
                                return {
                                    ...oldState,
                                    showConfirmation: false
                                };
                            });
                        });
                },
                cancelFunction: () => {
                    setConfirmationData((oldState) => {
                        return {
                            ...oldState,
                            showConfirmation: false
                        };
                    });
                }
            });
        },
        [loadData]
    );

    const handleChangePrintStatus = useCallback(async (id: string) => {
        await apiGateway.put(`/orders/${id}/print_status`).then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrdesDelivery((oldState) => {
                    return [
                        ...oldState.map((order) => {
                            return {
                                ...order,
                                was_printed: order.id === id ? true : order.was_printed
                            };
                        })
                    ];
                });
            }
        });
    }, []);

    let poolingTimeout;
    const pollingData = useCallback(async () => {
        await loadData();
        poolingTimeout = setTimeout(() => pollingData(), 60000);
    }, [loadData]);

    const [orderToPrint, setOrderToPrint] = useState(null);
    const promiseResolve: any = useRef();

    const onBeforeGetContent = (id: string) => {
        return new Promise((resolve) => {
            apiGateway.get(`/orders/${id}`).then((response) => {
                if (response.status === HTTP.STATUS.SUCCESS) {
                    setOrderToPrint(response.data);
                    promiseResolve.current = resolve;
                }
            });
        });
    };

    useEffect(() => {
        if (orderToPrint) {
            promiseResolve.current();
        }
    }, [orderToPrint]);

    useEffect(() => {
        changeTitle('Dashboard');
        pollingData();
        apiGateway.get('/orders/delivery?status=TOMORROW').then((response) => {
            if (response.status === HTTP.STATUS.SUCCESS) {
                setOrdesTomorrow(response.data);
            }
        });
        return () => {
            clearTimeout(poolingTimeout);
        };
    }, [changeTitle, loadData, pollingData, user]);

    return (
        <Flex width="100%" flexDirection="column">
            {(!user?.permission || user?.permission === 'Admin') && (
                <Flex width="100%" justifyContent="flex-start" flexWrap="wrap" mb="24px">
                    <Flex
                        width="200px"
                        backgroundColor="white"
                        p="16px"
                        flexDirection="column"
                        mr="16px"
                        mb="8px"
                    >
                        <Flex width="100%" justifyContent="space-between">
                            <Text color="gray.800" fontSize="14px" fontWeight="500">
                                Total Hoje
                            </Text>
                            <FiDollarSign size={20} />
                        </Flex>
                        <Text color="green.500" fontSize="18px">
                            {CurrencyUtils.numberToCurrency(tta.total_today)}
                        </Text>
                        <Divider />
                    </Flex>

                    <Flex
                        width="200px"
                        backgroundColor="white"
                        p="16px"
                        flexDirection="column"
                        mr="16px"
                        mb="8px"
                    >
                        <Flex width="100%" justifyContent="space-between">
                            <Text color="gray.800" fontSize="14px" fontWeight="500">
                                Total últimos 30 dias
                            </Text>
                            <FiCalendar size={20} />
                        </Flex>
                        <Text color="green.500" fontSize="18px">
                            {CurrencyUtils.numberToCurrency(tta.total_last_30_days)}
                        </Text>
                        <Divider />
                    </Flex>

                    <Flex
                        width="200px"
                        backgroundColor="white"
                        p="16px"
                        flexDirection="column"
                        mr="16px"
                        mb="8px"
                    >
                        <Flex width="100%" justifyContent="space-between">
                            <Text color="gray.800" fontSize="14px" fontWeight="500">
                                Ticket médio
                            </Text>
                            <FiPercent size={20} />
                        </Flex>
                        <Text color="green.500" fontSize="18px">
                            {CurrencyUtils.numberToCurrency(tta.avg_ticket)}
                        </Text>
                        <Divider />
                    </Flex>

                    <Flex
                        width="200px"
                        backgroundColor="white"
                        p="16px"
                        flexDirection="column"
                        mr="16px"
                        mb="8px"
                    >
                        <Flex width="100%" justifyContent="space-between">
                            <Text color="gray.800" fontSize="14px" fontWeight="500">
                                Entregas para Hoje
                            </Text>
                        </Flex>
                        <Flex fontSize="14px" color="green.500">
                            <Text mr="8px">Manhã</Text>
                            <Text>
                                {
                                    ordersDelivery.filter((item) => item.delivery_hour === 'Manhã')
                                        .length
                                }
                            </Text>
                        </Flex>
                        <Flex fontSize="14px" color="green.500">
                            <Text mr="8px">Tarde</Text>
                            <Text>
                                {
                                    ordersDelivery.filter((item) => item.delivery_hour === 'Tarde')
                                        .length
                                }
                            </Text>
                        </Flex>
                        <Divider />
                    </Flex>

                    <Flex
                        width="200px"
                        backgroundColor="white"
                        p="16px"
                        flexDirection="column"
                        mr="16px"
                        mb="8px"
                    >
                        <Flex width="100%" justifyContent="space-between">
                            <Text color="gray.800" fontSize="14px" fontWeight="500">
                                Entregas para Amanhã
                            </Text>
                        </Flex>
                        <Flex fontSize="14px" color="green.500">
                            <Text mr="8px">Manhã</Text>
                            <Text>
                                {
                                    ordersTomorrow.filter((item) => item.delivery_hour === 'Manhã')
                                        .length
                                }
                            </Text>
                        </Flex>
                        <Flex fontSize="14px" color="green.500">
                            <Text mr="8px">Tarde</Text>
                            <Text>
                                {
                                    ordersTomorrow.filter((item) => item.delivery_hour === 'Tarde')
                                        .length
                                }
                            </Text>
                        </Flex>
                        <Divider />
                    </Flex>
                </Flex>
            )}

            <ConfirmationPanel
                code={confirmationData.code}
                showConfirmation={confirmationData.showConfirmation}
                confirmationFunction={confirmationData.confirmationFunction}
                cancelFunction={confirmationData.cancelFunction}
            />

            <Flex width="100%" flexDirection="column" backgroundColor="white" px="24px">
                <Flex width="100%" py="8px" justifyContent="space-between">
                    <Form
                        ref={formRef as any}
                        style={{
                            display: 'flex'
                        }}
                        onSubmit={() => null}
                    >
                        <Flex justifyContent="flex-start" alignItems="center">
                            <Text mr="48px" fontSize="18px" color="purple.500" whiteSpace="nowrap">
                                Entregas
                                {DAYS[deliveriesMoment]}
                            </Text>

                            <Flex alignItems="center">
                                <Input
                                    width="200px"
                                    name="query"
                                    placeholder="Pesquisar por código"
                                    size="sm"
                                    mb="0px"
                                    onEnterPress={() => handleSearchDelivery()}
                                />

                                <Button
                                    height="32px"
                                    width="40px"
                                    borderRadius="2px"
                                    ml="8px"
                                    backgroundColor="green.500"
                                    cursor="pointer"
                                    onClick={() => handleSearchDelivery()}
                                >
                                    <Icon as={FiSearch} size="20px" />
                                </Button>
                            </Flex>
                        </Flex>
                    </Form>
                    <Flex width="50%" justifyContent="flex-end" alignItems="center">
                        <Text mr="16px" fontSize="12px">
                            Mostrar:
                        </Text>
                        <PanelButton
                            text="Atrasadas"
                            isSelected={deliveriesMoment === 'DELAYED'}
                            buttonFunction={() => showDeliveries('DELAYED')}
                        />
                        <PanelButton
                            text="Hoje"
                            isSelected={deliveriesMoment === 'TODAY'}
                            buttonFunction={() => showDeliveries('TODAY')}
                        />
                        <PanelButton
                            text="Amanhã"
                            isSelected={deliveriesMoment === 'TOMORROW'}
                            buttonFunction={() => showDeliveries('TOMORROW')}
                        />
                        <PanelButton
                            mr="0px"
                            text="Futuras"
                            isSelected={deliveriesMoment === 'FUTURE'}
                            buttonFunction={() => showDeliveries('FUTURE')}
                        />
                    </Flex>
                </Flex>

                <Divider my="0px" />

                {ordersDelivery.length > 0 && (
                    <Flex width="100%" flexDirection="column">
                        <Flex
                            width="100%"
                            py="8px"
                            justifyContent="space-between"
                            fontSize="12px"
                            maxWidth="100%"
                            textTransform="uppercase"
                        >
                            <Flex width="100%" justifyContent="center" maxWidth="96px">
                                <Text>Pedido</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center">
                                <Text>Cliente</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center">
                                <Text>Valor</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="184px" px="4px">
                                <Text>Status de Pagamento</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="184px" px="4px">
                                <Text>Status de Entrega</Text>
                            </Flex>
                            <Text
                                width="100%"
                                justifyContent="center"
                                maxWidth="104px"
                                textAlign="center"
                            >
                                Cidade
                            </Text>
                            <Flex width="100%" justifyContent="center">
                                <Text>Data de Entrega</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="96px">
                                <Text>Ação</Text>
                            </Flex>
                        </Flex>
                        <Divider my="0px" />
                        {ordersDelivery.map((item) => (
                            <Flex
                                key={`${item.id}${item.code}${item.delivery_status}`}
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                alignItems="center"
                                maxWidth="100%"
                                fontSize="12px"
                            >
                                <Flex width="100%" justifyContent="center" maxWidth="96px">
                                    <Text>{item.code}</Text>
                                </Flex>
                                <Flex width="100%" justifyContent="center" textAlign="center">
                                    <Text>{item.buyer.name}</Text>
                                </Flex>
                                <Flex width="100%" justifyContent="center">
                                    <Text>{CurrencyUtils.numberToCurrency(item.total_value)}</Text>
                                </Flex>
                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <PaymentStatus
                                        orderId={item.id}
                                        defaultStatus={item.payment_status}
                                        onChangeFunction={handleChangePaymentStatus}
                                        width="100%"
                                        isChangeable
                                    />
                                </Flex>
                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <DeliveryStatus
                                        defaultStatus={item.delivery_status}
                                        isChangeable={false}
                                        onChangeFunction={undefined}
                                        orderId={item.id}
                                        width="100%"
                                    />
                                </Flex>

                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    maxWidth="104px"
                                    textAlign="center"
                                >
                                    <Text>{item.delivery_address.city}</Text>
                                </Flex>

                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {`${DateUtils.formatStringToDate(item.delivery_date)} - ${
                                            item.delivery_hour
                                        }`}
                                    </Text>
                                </Flex>
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    maxWidth="96px"
                                >
                                    <PseudoBox
                                        title="Visualizar"
                                        p="4px"
                                        backgroundColor="purple.500"
                                        color="white"
                                        borderRadius="2px"
                                        mr="4px"
                                        cursor="pointer"
                                        onClick={() => handleViewOrderDetails(item.id)}
                                    >
                                        <FiEye size={12} />
                                    </PseudoBox>

                                    {(item.payment_method.name === 'Depósito' ||
                                        item.payment_method.name === 'Depósito/Pix') && (
                                        <PseudoBox
                                            title="VER COMPROVANTE"
                                            p="4px"
                                            backgroundColor={
                                                !!item.url_voucher === true
                                                    ? 'green.500'
                                                    : 'red.500'
                                            }
                                            color="white"
                                            borderRadius="2px"
                                            mr="4px"
                                            cursor="pointer"
                                            onClick={() => handleViewOrderVoucher(item.id)}
                                        >
                                            <FiFile size={12} />
                                        </PseudoBox>
                                    )}

                                    {item.delivery_status !== 'ENTREGUE' && (
                                        <PseudoBox
                                            title={
                                                item.delivery_status === 'SAIU_PARA_ENTREGA'
                                                    ? 'Confirmar Entrega'
                                                    : 'Sair para Entrega'
                                            }
                                            p="4px"
                                            backgroundColor={
                                                item.delivery_status === 'RETIRADA_NA_LOJA'
                                                    ? 'gray.700'
                                                    : 'green.500'
                                            }
                                            mr="4px"
                                            color="white"
                                            borderRadius="2px"
                                            cursor="pointer"
                                            onClick={() =>
                                                item.delivery_status !== 'RETIRADA_NA_LOJA'
                                                    ? handleChangeDeliveryStatus(
                                                          item.code,
                                                          item.id,
                                                          item.delivery_status ===
                                                              'SAIU_PARA_ENTREGA'
                                                              ? 'ENTREGUE'
                                                              : 'SAIU_PARA_ENTREGA'
                                                      )
                                                    : null
                                            }
                                        >
                                            {item.delivery_status === 'SAIU_PARA_ENTREGA' ? (
                                                <FiCheck size={12} />
                                            ) : (
                                                <FiTruck size={12} />
                                            )}
                                        </PseudoBox>
                                    )}

                                    <PseudoBox
                                        title="VER CARTÃO"
                                        mr="4px"
                                        p="3px"
                                        backgroundColor="blue.500"
                                        color="white"
                                        borderRadius="2px"
                                        cursor="pointer"
                                        onClick={() => navigate(`/orders/${item.id}/card/edit`)}
                                        position="relative"
                                    >
                                        <MdOutlineEmail size={16} />

                                        {item?.is_card_printed && (
                                            <PseudoBox
                                                position="absolute"
                                                bottom="-8px"
                                                right="-8px"
                                                color="white"
                                                backgroundColor="green.500"
                                                p="2px"
                                                borderRadius="2px"
                                                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                                                zIndex={1000}
                                            >
                                                <BiCheckDouble />
                                            </PseudoBox>
                                        )}
                                    </PseudoBox>

                                    <ReactToPrint
                                        content={() => printRefs[item.id].current}
                                        pageStyle={`
                      *, h1, h2, h3, h4, h5, p, a {
                        color: black!important;
                      `}
                                        onBeforeGetContent={() => onBeforeGetContent(item.id)}
                                        onBeforePrint={() => {
                                            setOrderToPrint(null);
                                            handleChangePrintStatus(item.id);
                                        }}
                                    >
                                        <PrintContextConsumer>
                                            {({ handlePrint }) => (
                                                <PseudoBox
                                                    position="relative"
                                                    title="Imprimir"
                                                    p="4px"
                                                    backgroundColor={
                                                        item.was_printed ? 'white' : 'white'
                                                    }
                                                    color="purple.500"
                                                    border="2px solid"
                                                    borderColor="purple.500"
                                                    borderRadius="2px"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        handlePrint();
                                                    }}
                                                >
                                                    <FiPrinter />

                                                    {item.was_printed && (
                                                        <PseudoBox
                                                            position="absolute"
                                                            bottom="-8px"
                                                            right="-8px"
                                                            color="white"
                                                            backgroundColor="green.500"
                                                            p="2px"
                                                            borderRadius="2px"
                                                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                                                        >
                                                            <BiCheckDouble />
                                                        </PseudoBox>
                                                    )}
                                                </PseudoBox>
                                            )}
                                        </PrintContextConsumer>
                                    </ReactToPrint>
                                </Flex>
                                <Flex display="none">
                                    <Flex ref={printRefs[item.id]}>
                                        {orderToPrint && (
                                            <OrderPrint
                                                key={orderToPrint.id}
                                                order={orderToPrint}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                )}

                {ordersDelivery.length <= 0 && (
                    <Flex width="100%" py="8px">
                        <Text color="gray.700">Nenhuma entrega encontrada</Text>
                    </Flex>
                )}
            </Flex>

            <Flex width="100%" flexDirection="column" backgroundColor="white" mt="24px" px="24px">
                <Flex width="100%" py="8px" justifyContent="space-between" flexWrap="wrap">
                    <Flex width="50%" justifyContent="flex-start" alignItems="center">
                        <Text mr="16px" fontSize="18px" color="purple.500">
                            Pagamento na Loja
                        </Text>
                    </Flex>
                    <Flex width="50%" justifyContent="flex-end" alignItems="center">
                        <Text mr="16px" fontSize="12px">
                            Mostrar:
                        </Text>
                        <PanelButton
                            text="Hoje"
                            isSelected={payInStoreMoment === 'TODAY'}
                            buttonFunction={() => showPayInStore('TODAY')}
                        />
                        <PanelButton
                            text="Amanhã"
                            isSelected={payInStoreMoment === 'TOMORROW'}
                            buttonFunction={() => showPayInStore('TOMORROW')}
                        />
                        <PanelButton
                            mr="0px"
                            text="Futuras"
                            isSelected={payInStoreMoment === 'FUTURE'}
                            buttonFunction={() => showPayInStore('FUTURE')}
                        />
                    </Flex>
                </Flex>

                <Divider my="0px" />

                {payInStoreOrders.length > 0 && (
                    <Flex width="100%" flexDirection="column">
                        <Flex
                            width="100%"
                            py="8px"
                            justifyContent="space-between"
                            fontSize="14px"
                            maxWidth="100%"
                        >
                            <Flex width="100%" justifyContent="center" maxWidth="96px">
                                <Text>Pedido</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center">
                                <Text>Cliente</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center">
                                <Text>Valor</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="184px" px="4px">
                                <Text>Status de Pagamento</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="184px" px="4px">
                                <Text>Status de Entrega</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center">
                                <Text>Data de Entrega</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="center" maxWidth="96px">
                                <Text>Ação</Text>
                            </Flex>
                        </Flex>
                        <Divider my="0px" />
                        {payInStoreOrders.map((item) => (
                            <Flex
                                key={`${item.id}${item.code}${item.delivery_status}`}
                                width="100%"
                                py="8px"
                                justifyContent="space-between"
                                alignItems="center"
                                maxWidth="100%"
                                fontSize="14px"
                            >
                                <Flex width="100%" justifyContent="center" maxWidth="96px">
                                    <Text>{item.code}</Text>
                                </Flex>
                                <Flex width="100%" justifyContent="center">
                                    <Text>{item.buyer.name}</Text>
                                </Flex>
                                <Flex width="100%" justifyContent="center">
                                    <Text>{CurrencyUtils.numberToCurrency(item.total_value)}</Text>
                                </Flex>
                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <PaymentStatus
                                        orderId={item.id}
                                        defaultStatus={item.payment_status}
                                        onChangeFunction={handleChangePaymentStatus}
                                        width="100%"
                                        isChangeable
                                    />
                                </Flex>
                                <Flex width="100%" justifyContent="center" maxWidth="184px">
                                    <DeliveryStatus
                                        defaultStatus={item.delivery_status}
                                        minWidth="172px"
                                        isChangeable={false}
                                        onChangeFunction={undefined}
                                        orderId={item.id}
                                    />
                                </Flex>
                                <Flex width="100%" justifyContent="center">
                                    <Text>
                                        {`${DateUtils.formatStringToDate(item.delivery_date)} - ${
                                            item.delivery_hour
                                        }`}
                                    </Text>
                                </Flex>
                                <Flex
                                    width="100%"
                                    justifyContent="center"
                                    alignItems="center"
                                    maxWidth="96px"
                                >
                                    <PseudoBox
                                        title="Visualizar"
                                        p="8px"
                                        backgroundColor="purple.500"
                                        color="white"
                                        borderRadius="2px"
                                        mr="8px"
                                        cursor="pointer"
                                        onClick={() => handleViewOrderDetails(item.id)}
                                    >
                                        <FiEye size={14} />
                                    </PseudoBox>

                                    {item.delivery_status !== 'ENTREGUE' && (
                                        <PseudoBox
                                            title={
                                                item.delivery_status === 'SAIU_PARA_ENTREGA'
                                                    ? 'Confirmar Entrega'
                                                    : 'Sair para Entrega'
                                            }
                                            p="8px"
                                            backgroundColor={
                                                item.delivery_status === 'RETIRADA_NA_LOJA'
                                                    ? 'gray.700'
                                                    : 'green.500'
                                            }
                                            mr="8px"
                                            color="white"
                                            borderRadius="2px"
                                            cursor="pointer"
                                            onClick={() =>
                                                item.delivery_status !== 'RETIRADA_NA_LOJA'
                                                    ? handleChangeDeliveryStatus(
                                                          item.id,
                                                          item.id,
                                                          item.delivery_status ===
                                                              'SAIU_PARA_ENTREGA'
                                                              ? 'ENTREGUE'
                                                              : 'SAIU_PARA_ENTREGA'
                                                      )
                                                    : null
                                            }
                                        >
                                            {item.delivery_status === 'SAIU_PARA_ENTREGA' ? (
                                                <FiCheck size={14} />
                                            ) : (
                                                <FiTruck size={14} />
                                            )}
                                        </PseudoBox>
                                    )}

                                    <PseudoBox
                                        title="VER CARTÃO"
                                        mr="4px"
                                        p="3px"
                                        backgroundColor="blue.500"
                                        color="white"
                                        borderRadius="2px"
                                        cursor="pointer"
                                        onClick={() => navigate(`/orders/${item.id}/card/edit`)}
                                        position="relative"
                                    >
                                        <MdOutlineEmail size={16} />

                                        {item?.is_card_printed && (
                                            <PseudoBox
                                                position="absolute"
                                                bottom="-8px"
                                                right="-8px"
                                                color="white"
                                                backgroundColor="green.500"
                                                p="2px"
                                                borderRadius="2px"
                                                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                                                zIndex={1000}
                                            >
                                                <BiCheckDouble />
                                            </PseudoBox>
                                        )}
                                    </PseudoBox>

                                    <ReactToPrint
                                        content={() => printRefs[item.id].current}
                                        pageStyle={`
                      *, h1, h2, h3, h4, h5, p, a {
                        color: black!important;
                      `}
                                        onBeforeGetContent={() => onBeforeGetContent(item.id)}
                                        onBeforePrint={() => {
                                            setOrderToPrint(null);
                                            handleChangePrintStatus(item.id);
                                        }}
                                    >
                                        <PrintContextConsumer>
                                            {({ handlePrint }) => (
                                                <PseudoBox
                                                    position="relative"
                                                    title="Imprimir"
                                                    p="6px"
                                                    backgroundColor={
                                                        item.was_printed ? 'white' : 'white'
                                                    }
                                                    color="purple.500"
                                                    border="2px solid"
                                                    borderColor="purple.500"
                                                    borderRadius="2px"
                                                    cursor="pointer"
                                                    onClick={() => {
                                                        handlePrint();
                                                    }}
                                                >
                                                    <FiPrinter />

                                                    {item.was_printed && (
                                                        <PseudoBox
                                                            position="absolute"
                                                            bottom="-8px"
                                                            right="-8px"
                                                            color="white"
                                                            backgroundColor="green.500"
                                                            p="2px"
                                                            borderRadius="2px"
                                                            boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
                                                        >
                                                            <BiCheckDouble />
                                                        </PseudoBox>
                                                    )}
                                                </PseudoBox>
                                            )}
                                        </PrintContextConsumer>
                                    </ReactToPrint>
                                </Flex>
                                <Flex display="none">
                                    <Flex ref={printRefs[item.id]}>
                                        {orderToPrint && (
                                            <OrderPrint
                                                key={orderToPrint.id}
                                                order={orderToPrint}
                                            />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        ))}
                    </Flex>
                )}

                {payInStoreOrders.length <= 0 && (
                    <Flex width="100%" py="16px">
                        <Text color="gray.700">Nenhum pedido para ser pago na loja no momento</Text>
                    </Flex>
                )}
            </Flex>
        </Flex>
    );
};

export default Dashboard;
