import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../components/Button';
import Checkbox from '../../../components/Form/Checkbox';
import Input from '../../../components/Form/Input';
import Select from '../../../components/Form/Select';
import { HTTP } from '../../../shared/constants';
import apiGateway from '../../../shared/services/apiGateway';
import UploadBannerImage from '../components/UploadBannerImage';
import BannerFormHelper from '../helpers/BannerFormHelper';

const BannersEdit: React.FC = () => {
    const { id } = useParams() as any;
    const formRef = useRef<FormHandles>();
    const [banner, setBanner] = useState(null);
    const [bannerWeb, setBannerWeb] = useState(null);
    const [bannerMobile, setBannerMobile] = useState(null);
    const [isEnable, setEnable] = useState(false);
    const [bannerType, setBannerType] = useState('SLIDE');
    const navigate = useNavigate();
    const toast = useToast();
    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.put(`/banners/${id}`, {
                    type: formData.type,
                    name: formData.name,
                    link: formData.link,
                    url_web: bannerWeb || '',
                    url_mobile: bannerMobile || '',
                    priority: 100,
                    is_enable: isEnable
                });

                if (response.status === HTTP.STATUS.SUCCESS) {
                    BannerFormHelper.onSuccess(
                        'Edição Realizada',
                        'Banner atualizado com sucesso',
                        toast
                    );

                    navigate('/banners');
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    BannerFormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [bannerMobile, bannerWeb, navigate, id, isEnable, toast]
    );

    const handleUploadBannerWeb = useCallback((url: string) => {
        setBannerWeb(url);
    }, []);

    const handleUploadBannerMobile = useCallback((url: string) => {
        setBannerMobile(url);
    }, []);

    useEffect(() => {
        apiGateway.get(`/banners/${id}`).then((response) => {
            const bannerData = response.data;
            setBanner(bannerData);
            setEnable(bannerData.is_enable);
            setBannerWeb(bannerData.url_web);
            setBannerMobile(bannerData.url_mobile);
            setBannerType(bannerData.type);
        });
    }, [id]);

    return (
        <Form
            ref={formRef}
            onSubmit={handleSubmit}
            autoComplete="off"
            style={{
                width: '100%'
            }}
        >
            {banner != null && (
                <Flex
                    width="100%"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontWeight="600"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
                        Informações Básicas
                    </Text>
                    <Flex minWidth="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                        <Box width="32%">
                            <Input
                                name="name"
                                label="Nome"
                                isRequired
                                size="sm"
                                defaultValue={banner.name}
                            />
                        </Box>
                        <Box width="32%">
                            <Input
                                name="link"
                                label="Link"
                                isRequired
                                size="sm"
                                defaultValue={banner.link}
                            />
                        </Box>
                        <Box width="32%">
                            <Select
                                name="type"
                                label="Tipo"
                                isRequired
                                size="sm"
                                onChange={(e) => setBannerType(e.currentTarget.value)}
                            >
                                <option value="SLIDE" selected={banner.type === 'SLIDE'}>
                                    Slide
                                </option>
                                <option value="CONTENT" selected={banner.type === 'CONTENT'}>
                                    Banner de Conteúdo
                                </option>
                                <option
                                    value="MAKE_BASKET"
                                    selected={banner.type === 'MAKE_BASKET'}
                                >
                                    Monte sua Cesta
                                </option>
                            </Select>
                        </Box>
                    </Flex>

                    <Flex width="100%" mb="16px">
                        <Box fontWeight="500" mr="48px">
                            <Text>Ativar banner?</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable === false}
                                    onChange={() => setEnable(!isEnable)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>

                                <Checkbox
                                    name="is_enable"
                                    isChecked={isEnable}
                                    onChange={() => setEnable(!isEnable)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>

                    <Text>
                        {bannerType === 'SLIDE'
                            ? 'Banner Web - (1140 x 400)'
                            : 'Banner Web - (1140 x 200)'}
                    </Text>
                    <UploadBannerImage
                        defaultImage={bannerWeb}
                        onUploadFunction={handleUploadBannerWeb}
                    />

                    <Text mt="24px">
                        {bannerType === 'SLIDE'
                            ? 'Banner Mobile - (400 x 400)'
                            : 'Banner Mobile - (168 x 232)'}
                    </Text>
                    <UploadBannerImage
                        defaultImage={bannerMobile}
                        onUploadFunction={handleUploadBannerMobile}
                    />

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
                        Editar
                    </Button>
                </Flex>
            )}
        </Form>
    );
};

export default BannersEdit;
