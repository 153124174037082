import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Flex, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Button from '../../../../components/Button';
import Input from '../../../../components/Form/Input';
import TextArea from '../../../../components/Form/TextArea';
import { HTTP } from '../../../../shared/constants';
import FormHelper from '../../../../shared/helpers/FormHelper';
import apiGateway from '../../../../shared/services/apiGateway';
import UploadLogoImage from '../GeneralSettings/components/UploadLogoImage';

const ServicesEdit: React.FC = () => {
    const { id } = useParams() as any;

    const formRef = useRef<FormHandles>();
    const navigate = useNavigate();
    const toast = useToast();

    const [uploadLogo, setUploadLogo] = useState('');

    const handleUploadLogo = useCallback((url: string) => {
        setUploadLogo(url);
    }, []);

    const [setService, setSetService] = useState(null);

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const response = await apiGateway.put(
                    `/settings/set-services/${id}`,
                    {
                        name: formData.name,
                        title: formData.title,
                        url_image: uploadLogo,
                        description: formData.description,
                        link: formData.link
                    }
                );
                if (response.status === HTTP.STATUS.SUCCESS) {
                    FormHelper.onSuccess(
                        'Registro Atualizado',
                        'Serviço atualziado com sucesso',
                        toast
                    );
                }
            } catch (error) {
                if (error instanceof Yup.ValidationError) {
                    FormHelper.showErrors(formRef as any, error, toast);
                }
            }
        },
        [id, history, toast, uploadLogo]
    );

    useEffect(() => {
        apiGateway.get(`/settings/set-services/${id}`).then(response => {
            const { status } = response;
            if (status === HTTP.STATUS.SUCCESS) {
                setSetService(response.data);
                setUploadLogo(response.data.url_image);
            }
        });
    }, [id]);

    return (
        setService && (
            <Form
                ref={formRef as any}
                onSubmit={handleSubmit}
                placeholder=""
                autoComplete="off"
                style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                }}
            >
                <Flex
                    width="100%"
                    maxWidth="800px"
                    flexDirection="column"
                    backgroundColor="white"
                    px="24px"
                    py="16px"
                    position="relative"
                    fontWeight="500"
                    fontSize="14px"
                >
                    <Text color="purple.500" fontSize="20px" mt="16px">
                        Registrar novo serviço
                    </Text>

                    <Flex width="100%" flexDirection="column">
                        <Input
                            name="name"
                            label="Nome do Serviço"
                            isRequired
                            defaultValue={setService.name}
                        />

                        <Flex width="100%" flexDirection="column" mb="4">
                            <label>Imagem</label>
                            <UploadLogoImage
                                onUploadFunction={handleUploadLogo}
                                defaultImage={uploadLogo}
                            />
                        </Flex>

                        <Input
                            name="title"
                            label="Título"
                            defaultValue={setService.title}
                        />
                        <TextArea
                            name="description"
                            label="Descrição"
                            defaultValue={setService.description}
                        />
                        <Input
                            name="link"
                            label="Link"
                            isRequired
                            defaultValue={setService.link}
                        />
                    </Flex>

                    <Button
                        type="submit"
                        width="100%"
                        backgroundColor="purple.500"
                        height="48px"
                        mt="24px"
                    >
                        Atualizar
                    </Button>
                </Flex>
            </Form>
        )
    );
};

export default ServicesEdit;
