import React, { useCallback, useState } from 'react';
import { FiChevronDown, FiChevronRight } from 'react-icons/fi';

import { BadgeProps, Flex, PseudoBox, Text } from '@chakra-ui/core';

import TextUtils from '../../shared/utils/TextUtils';

const STATUS_AND_COLORS = {
    AGUARDANDO_PRODUCAO: 'yellow.500',
    EM_PRODUCAO: 'orange.400',
    PRODUZIDO: 'purple.500',
    SAIU_PARA_ENTREGA: 'blue.500',
    RETIRADA_NA_LOJA: 'pink.300',
    ENTREGUE: 'green.500'
};

const status = [
    'AGUARDANDO_PRODUCAO',
    'EM_PRODUCAO',
    'PRODUZIDO',
    'SAIU_PARA_ENTREGA',
    'RETIRADA_NA_LOJA',
    'ENTREGUE'
];

interface IProps extends BadgeProps {
  defaultStatus: any;
  orderId: string;
  onChangeFunction: (orderId: string, statusC: string) => any;
  isChangeable?: boolean;
}

const DeliveryStatus: React.FC<IProps> = ({
    defaultStatus,
    orderId,
    onChangeFunction,
    isChangeable = true,
    ...rest
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(defaultStatus);

    const handleOnChangeStatus = useCallback(
        async (newStatus: string) => {
            setSelectedStatus(newStatus);
            setIsOpen(false);
            await onChangeFunction(orderId, newStatus);
        },
        [onChangeFunction, orderId]
    );

    return (
        <Flex width="100%" justifyContent="center" position="relative" px="8px">
            <Flex
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                textAlign="center"
                backgroundColor={STATUS_AND_COLORS[selectedStatus]}
                color="white"
                height="32px"
                px="10px"
                cursor={isChangeable ? 'pointer' : 'default'}
                {...rest}
                onClick={() => (isChangeable ? setIsOpen(!isOpen) : null)}
            >
                <Flex width="100%" justifyContent="center">
                    <Text fontSize="10px" lineHeight="10px">
                        {String(selectedStatus).replace(/_/g, ' ')}
                    </Text>
                </Flex>
                {isChangeable && !isOpen && <FiChevronRight />}
                {isOpen && <FiChevronDown />}
            </Flex>
            <Flex
                position="absolute"
                flexDirection="column"
                mt="32px"
                display={isOpen ? 'flex' : 'none'}
                zIndex={1000}
                backgroundColor="white"
                p="8px"
                justifyContent="space-between"
                boxShadow="0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)"
            >
                {status.map(item => (
                    <PseudoBox
                        key={item}
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        textAlign="center"
                        backgroundColor={STATUS_AND_COLORS[item]}
                        color="white"
                        borderColor={STATUS_AND_COLORS[item]}
                        height="32px"
                        px="8px"
                        mb="8px"
                        {...rest}
                        onClick={() => handleOnChangeStatus(item)}
                        cursor="pointer"
                        _last={{
                            mb: 0
                        }}
                        _hover={{
                            borderColor: 'purple.500',
                            color: 'purple.500'
                        }}
                        fontSize="10px"
                    >
                        <Text>{TextUtils.convertDeliveryStatus(item)}</Text>
                    </PseudoBox>
                ))}
            </Flex>
        </Flex>
    );
};

export default DeliveryStatus;
