import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
    AiOutlineAppstoreAdd,
    AiOutlineCluster,
    AiOutlineColumnHeight,
    AiOutlineEdit
} from 'react-icons/ai';
import { FaPlus, FaRegImages, FaTrash } from 'react-icons/fa';
import { FiTrendingUp, FiTruck } from 'react-icons/fi';
import { RiSave3Line } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Box, Divider, Flex, Radio, Stack, Text, useToast } from '@chakra-ui/core';
import { FormHandles } from '@unform/core';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import Checkbox from '../../../../components/Form/Checkbox';
import Input from '../../../../components/Form/Input';
import InputCurrency from '../../../../components/Form/InputCurrency';
import Select from '../../../../components/Form/Select';
import TextArea from '../../../../components/Form/TextArea';
import TextEditor from '../../../../components/Form/TextEditor';
import { HTTP } from '../../../../shared/constants';
import { useAuth } from '../../../../shared/hooks/useAuth';
import apiGateway from '../../../../shared/services/apiGateway';
import NumberUtils from '../../../../shared/utils/NumberUtils';
import SelectCategories from '../../components/Form/SelectCategories';
import SelectSubtypes from '../../components/Form/SelectSubtypes';
import SelectTags from '../../components/Form/SelectTags';
import UploadProductImage from '../../components/Form/UploadProductImage';
import UploadVariantImage from '../../components/Form/UploadVariantImage';
import { useProductForm } from '../../contexts/ProductFormProvider';
import ProductFormHelper from '../../helpers/ProductFormHelper';

const ProductsRegister: React.FC = () => {
    const formRef = useRef<FormHandles>();
    const toast = useToast();
    const {
        setEnable,
        setPromotional,
        setFreeShipping,
        addTypes,
        addVariant,
        editVariant,
        removeVariants,
        getAttributes
    } = useProductForm();

    const {
        is_enable,
        is_promotional,
        is_free_shipping,
        images,
        categories,
        types,
        subtypes,
        tags,
        variants
    } = getAttributes();

    const [defaultMultiSelect] = useState(null);
    const [defaultMultiTypes, setDefaultMultiTypes] = useState(null);
    const [showVariants, setShowVariants] = useState(false);
    const [isStock, setIsStock] = useState(false);

    const navigate = useNavigate();

    const { branch } = useAuth();

    const [generalSettings, setGeneralSettings] = useState(null);

    const handleCalcMarcap = useCallback(() => {
        const costValue = formRef.current.getFieldValue('price_purchase');
        const marcapValue = formRef.current.getFieldValue('marcap');

        if (costValue && marcapValue) {
            const value1 = parseFloat(costValue.replace(/\./g, '').replace(',', '.')).toFixed(2);

            const value2 = parseFloat(marcapValue.replace(/\./g, '').replace(',', '.')).toFixed(2);

            const result = Number(value1) + Number(value1) * (Number(value2) / 100);

            formRef.current.getFieldRef('price_sale').value = Number(result).toLocaleString(
                'pt-BR',
                {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }
            );
        }
    }, []);

    const handleChangeName = useCallback(() => {
        const name = formRef.current.getFieldValue('name');

        formRef.current.getFieldRef('slug').value = name;
        formRef.current.getFieldRef('title').value = name;
    }, []);

    const handleEditVariant = useCallback(
        (variantName) => {
            const { value } = formRef.current.getFieldRef(`${variantName}_price`);

            editVariant(variantName, 'price', NumberUtils.formatNumber(value));
        },
        [editVariant]
    );

    const handleSubmit = useCallback(
        async (formData: any) => {
            try {
                const productData = await ProductFormHelper.makeValidProductDataToSend({
                    formData,
                    is_enable,
                    is_promotional,
                    is_free_shipping,
                    tags,
                    images,
                    categories,
                    types,
                    subtypes,
                    cover_id: images.find((image) => image?.is_cover === true)?.id,
                    stock: {
                        quantity: formData.num_stock,
                        branch_id: branch
                    }
                });

                productData.variants = variants;

                const checkVariants = [...variants.colors, ...variants.sizes];

                if (checkVariants && checkVariants.length > 0) {
                    let shouldContinue = true;
                    checkVariants.forEach((variant) => {
                        if (variant.price <= 0) {
                            ProductFormHelper.onError(
                                'Registro não efetuado',
                                'A variante do produto precisa ter um preço válido',
                                toast
                            );

                            shouldContinue = false;
                        }
                    });

                    if (!shouldContinue) return;
                }

                apiGateway
                    .post('/products', {
                        ...productData,
                        is_stock: isStock
                    })
                    .then((response) => {
                        if (response.status === HTTP.STATUS.CREATED) {
                            ProductFormHelper.onSuccess(
                                'Registro efetuado',
                                'Produto cadastrado com sucesso',
                                toast
                            );

                            navigate('/products');
                        }

                        if (response.status === HTTP.STATUS.BAD_REQUEST) {
                            ProductFormHelper.onError(
                                'Registro não efetuado',
                                response.data.message,
                                toast
                            );
                        }
                    });
            } catch (error) {
                console.log(error);
                if (error instanceof Yup.ValidationError) {
                    ProductFormHelper.showErrors(formRef, error, toast);
                }
            }
        },
        [
            is_enable,
            is_promotional,
            is_free_shipping,
            tags,
            images,
            categories,
            types,
            subtypes,
            branch,
            variants,
            toast,
            history,
            isStock
        ]
    );

    const handleTypeChange = useCallback(
        (value) => {
            addTypes([value], null);
        },
        [addTypes]
    );

    useEffect(() => {
        window.scrollTo(0, 0);

        apiGateway.get('/products/types?query').then((response) => {
            const typesResponse = response.data;

            setDefaultMultiTypes(
                typesResponse.map((type) => {
                    return {
                        id: type.id,
                        name: type.value,
                        text: type.value
                    };
                })
            );

            addTypes([typesResponse[0].id], null);
        });
    }, [addTypes]);

    useEffect(() => {
        apiGateway.get('/settings/general_settings').then((response) => {
            setGeneralSettings(response.data);
        });
    }, []);

    return (
        <Form ref={formRef as any} onSubmit={handleSubmit}>
            <Flex
                width="100%"
                maxWidth="100%"
                flexDirection="column"
                backgroundColor="white"
                px="24px"
                py="16px"
                position="relative"
                fontWeight="500"
                fontSize="14px"
            >
                <Flex width="100%">
                    <Flex width="100%" justifyContent="space-between">
                        <Flex flexDirection="column">
                            <Text>Selecione o tipo de produto</Text>
                            <Stack direction="row" mt="8px" mb="24px" spacing={8}>
                                {defaultMultiTypes !== null &&
                                    defaultMultiTypes.map((type) => (
                                        <Flex key={type} flexDirection="column">
                                            {(generalSettings?.custom_card ||
                                                type.name !== 'Cartão Custom') && (
                                                <Radio
                                                    key={type.id}
                                                    size="md"
                                                    name={`product_type_${type.id}`}
                                                    variantColor="green"
                                                    isChecked={types.ids.includes(type.id)}
                                                    value={type.id}
                                                    onFocus={(e) => {
                                                        handleTypeChange(type.id);
                                                    }}
                                                >
                                                    <Text fontSize="14px">{type.name}</Text>
                                                </Radio>
                                            )}
                                            {type.name === 'Padrão' && (
                                                <Text fontSize="12px" color="red.500">
                                                    Buquês, cestas, arranjos...
                                                </Text>
                                            )}

                                            {type.name === 'Complemento' && (
                                                <Text fontSize="12px" color="red.500">
                                                    Vinhos, chocolates, ursinhos...
                                                </Text>
                                            )}

                                            {type.name === 'Monte sua Cesta' && (
                                                <Text fontSize="12px" color="red.500">
                                                    Item para o monte sua cesta
                                                </Text>
                                            )}

                                            {type.name === 'Cartão Custom' && (
                                                <Text fontSize="12px" color="red.500">
                                                    Cartão Customizado
                                                </Text>
                                            )}
                                        </Flex>
                                    ))}
                            </Stack>
                        </Flex>
                        <Box fontWeight="500" mr="48px">
                            <Text>Mostrar no site</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={is_enable === false}
                                    onChange={() => setEnable(!is_enable)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_enable"
                                    isChecked={is_enable}
                                    onChange={() => setEnable(!is_enable)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>
                </Flex>
                <Flex alignItems="center">
                    <Flex mt="12px" mr="8px" color="purple.500">
                        <FaRegImages size={24} />
                    </Flex>
                    <Text color="purple.500" fontSize="20px" mt="16px">
                        Fotos
                    </Text>
                </Flex>
                <Box display="flex" width="100%" mt="8px">
                    <UploadProductImage />
                    <UploadProductImage />
                    <UploadProductImage />
                    <UploadProductImage />
                </Box>

                <Divider mt="24px" mb="16px" />

                <Flex flexDirection="column">
                    <Flex alignItems="center">
                        <Flex mr="8px" color="purple.500">
                            <AiOutlineEdit size={24} />
                        </Flex>
                        <Text color="purple.500" fontSize="20px">
                            Informações Básicas
                        </Text>
                    </Flex>

                    <Flex width="100%" justifyContent="space-between" flexWrap="wrap" mt="8px">
                        <Box width="32%">
                            <Input name="code_sku" label="Código" isRequired size="sm" />
                        </Box>

                        <Box width="32%">
                            <Input
                                name="name"
                                label="Nome"
                                isRequired
                                size="sm"
                                onChange={() => handleChangeName()}
                            />
                        </Box>

                        <Box width="32%">
                            <Input
                                name="slug"
                                label="Slug"
                                isRequired
                                size="sm"
                                tooltip
                                tooltipMessage="O slug é utilizado na url da pagina de detalhes do produto Ex: meusite.com.br/slug-do-produto."
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="price_purchase"
                                label="Valor de Custo"
                                size="sm"
                                defaultValue="0"
                                onChange={() => handleCalcMarcap()}
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="marcap"
                                label="Marcap %  (Margem)"
                                size="sm"
                                defaultValue="0"
                                badge="porcentage"
                                onChange={() => handleCalcMarcap()}
                                tooltip
                                tooltipMessage="O marcap é utilizado para calcular o valor de venda baseado no custo do produto e a porcentagem do marcap."
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="price_sale"
                                label="Valor Venda"
                                size="sm"
                                isRequired
                                defaultValue="0"
                            />
                        </Box>

                        <Box width="20%">
                            <InputCurrency
                                name="price_promotional"
                                label="Valor Promocional"
                                size="sm"
                                defaultValue="0"
                            />
                        </Box>

                        <Flex width="100%">
                            <Box fontWeight="500" mr="24px">
                                <Text>Gerenciar Estoque?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={isStock === false}
                                        onChange={() => setIsStock(!isStock)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={isStock}
                                        onChange={() => setIsStock(!isStock)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>

                            <Box width="144px">
                                <Input
                                    name="num_stock"
                                    label="Unidades em Estoque"
                                    size="sm"
                                    defaultValue="0"
                                />
                            </Box>
                        </Flex>

                        <Flex width="100%">
                            <TextEditor
                                name="description"
                                label="Descrição"
                                isRequired
                                minHeight="200px"
                            />
                        </Flex>

                        <Flex width="100%">
                            <Box fontWeight="500">
                                <Text>Usar preço promocional ?</Text>
                                <Flex
                                    width="100%"
                                    justifyContent="space-between"
                                    maxWidth="80px"
                                    alignItems="flex-end"
                                    mt="8px"
                                >
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={is_promotional === false}
                                        onChange={() => setPromotional(!is_promotional)}
                                        value="0"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Não</Text>
                                    </Checkbox>
                                    <Checkbox
                                        name="is_promotional"
                                        isChecked={is_promotional}
                                        onChange={() => setPromotional(!is_promotional)}
                                        value="1"
                                        mr="16px"
                                    >
                                        <Text fontSize="14px">Sim</Text>
                                    </Checkbox>
                                </Flex>
                            </Box>
                        </Flex>
                    </Flex>
                </Flex>

                <Divider mt="24px" mb="16px" />

                <Flex alignItems="center">
                    <Flex mr="8px" color="purple.500">
                        <FiTruck size={24} />
                    </Flex>
                    <Text color="purple.500" fontSize="20px">
                        Frete / Entregas
                    </Text>
                </Flex>
                <Flex width="100%" mt="8px">
                    <Box width="32%">
                        <InputCurrency
                            name="extra_shipping_value"
                            placeholder="Ex: 10%"
                            label="Acrescentar valor do frete %"
                            size="sm"
                            badge="porcentage"
                            textAlign="left"
                        />
                    </Box>

                    <Box width="32%" fontWeight="500" ml="24px">
                        <Text>Frete grátis ?</Text>
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            maxWidth="80px"
                            alignItems="flex-end"
                            mt="8px"
                        >
                            <Checkbox
                                name="is_free_shipping"
                                isChecked={is_free_shipping === false}
                                onChange={() => setFreeShipping(!is_free_shipping)}
                                value="0"
                                mr="16px"
                            >
                                <Text fontSize="14px">Não</Text>
                            </Checkbox>
                            <Checkbox
                                name="is_free_shipping"
                                isChecked={is_free_shipping}
                                onChange={() => setFreeShipping(!is_free_shipping)}
                                value="1"
                                mr="16px"
                            >
                                <Text fontSize="14px">Sim</Text>
                            </Checkbox>
                        </Flex>
                    </Box>
                </Flex>

                <Divider mt="24px" mb="16px" />

                <Flex alignItems="center">
                    <Flex mr="8px" color="purple.500">
                        <FiTrendingUp size={24} />
                    </Flex>
                    <Text color="purple.500" fontSize="20px">
                        SEO
                    </Text>
                </Flex>
                <Flex width="100%" mt="8px" flexWrap="wrap" justifyContent="space-between">
                    <Box width="48%">
                        <Input name="title" label="Título" size="sm" isRequired />
                    </Box>

                    <Flex width="48%" height="100%">
                        <Box width="100%">
                            <SelectTags defaultMultiSelect={defaultMultiSelect} />
                        </Box>
                    </Flex>

                    <Box width="100%">
                        <TextArea
                            name="meta_description"
                            label="Meta Description"
                            isRequired
                            size="sm"
                            mb="4px"
                        />
                        <Text fontSize="10px" width="100%" textAlign="right" color="gray.600">
                            Núm caractéres 1452. (Meta: 200 a 230)
                        </Text>
                    </Box>
                </Flex>

                <Divider mt="24px" mb="16px" />

                <Flex alignItems="center">
                    <Flex mr="8px" color="purple.500">
                        <AiOutlineColumnHeight size={24} />
                    </Flex>
                    <Text color="purple.500" fontSize="20px">
                        Medidas
                    </Text>
                </Flex>

                <Flex width="100%" mt="8px" flexWrap="wrap">
                    <Box width="120px" mr="16px">
                        <Input name="width" label="Largura (CM)" size="sm" />
                    </Box>

                    <Box width="120px">
                        <Input name="height" label="Altura (CM)" size="sm" />
                    </Box>
                </Flex>

                <Divider mt="24px" mb="16px" />

                <Flex alignItems="center">
                    <Flex mr="8px" color="purple.500">
                        <AiOutlineCluster size={24} />
                    </Flex>
                    <Text color="purple.500" fontSize="20px">
                        Categorias
                    </Text>
                </Flex>
                <Flex width="100%" mt="8px" justifyContent="space-between" flexWrap="wrap">
                    <Box width="48%">
                        <SelectCategories defaultMultiSelect={defaultMultiSelect} />
                    </Box>

                    {types.ids.includes(
                        defaultMultiTypes?.find((item) => item.name === 'Monte sua Cesta')?.id
                    ) && (
                        <Box width="48%">
                            <SelectSubtypes defaultMultiSelect={defaultMultiSelect} />
                        </Box>
                    )}
                </Flex>

                <Divider mt="24px" mb="16px" />

                <Flex width="100%" flexDirection="column">
                    <Flex width="100%" justifyContent="space-between">
                        <Flex alignItems="center">
                            <Flex mr="8px" color="purple.500">
                                <AiOutlineAppstoreAdd size={24} />
                            </Flex>
                            <Text color="purple.500" fontSize="20px">
                                Variações
                            </Text>
                        </Flex>

                        <Box fontWeight="500" mr="48px">
                            <Text>Usar Variações</Text>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                maxWidth="80px"
                                alignItems="flex-end"
                                mt="8px"
                            >
                                <Checkbox
                                    name="is_enable"
                                    isChecked={showVariants === false}
                                    onChange={() => setShowVariants(!showVariants)}
                                    value="0"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Não</Text>
                                </Checkbox>
                                <Checkbox
                                    name="is_enable"
                                    isChecked={showVariants}
                                    onChange={() => setShowVariants(!showVariants)}
                                    value="1"
                                    mr="16px"
                                >
                                    <Text fontSize="14px">Sim</Text>
                                </Checkbox>
                            </Flex>
                        </Box>
                    </Flex>

                    {showVariants && (
                        <Flex width="100%" flexDirection="column">
                            <Flex alignItems="center">
                                <Flex width="100%" maxWidth="144px" mr="8px">
                                    <Select label="Tipo de Variação" name="variant_type" size="sm">
                                        <option value="color">Cor</option>
                                        <option value="size">Tamanho</option>
                                    </Select>
                                </Flex>

                                <Flex width="100%" maxWidth="184px" mr="8px">
                                    <Input
                                        label="Nome da Variação"
                                        name="variant_name"
                                        placeholder="Ex: Vermelho"
                                        size="sm"
                                    />
                                </Flex>

                                <Flex
                                    width="32px"
                                    height="32px"
                                    backgroundColor="green.500"
                                    color="white"
                                    borderRadius="4px"
                                    justifyContent="center"
                                    alignItems="center"
                                    mt="8px"
                                    cursor="pointer"
                                    onClick={() => addVariant(formRef)}
                                >
                                    <FaPlus />
                                </Flex>
                            </Flex>

                            <Flex flexDirection="column" mt="16px">
                                <Text color="purple.500">Tamanhos</Text>

                                <Flex
                                    border="2px solid"
                                    borderColor="gray.500"
                                    py="8px"
                                    px="16px"
                                    mt="8px"
                                    flexDirection="column"
                                >
                                    {variants?.sizes.length === 0 && (
                                        <Text color="gray.700">Nenhum tamanho cadastrado</Text>
                                    )}

                                    {variants?.sizes.length > 0 && (
                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%" justifyContent="space-between">
                                                <Text width="100%" maxWidth="96px">
                                                    Imagem
                                                </Text>
                                                <Text width="100%">Variação</Text>
                                                <Text width="100%" maxWidth="96px">
                                                    Estoque
                                                </Text>
                                                <Text width="100%" maxWidth="120px">
                                                    Preço
                                                </Text>
                                                <Text width="100%" maxWidth="80px">
                                                    Ação
                                                </Text>
                                            </Flex>

                                            {variants?.sizes.map((color) => (
                                                <Flex
                                                    key={color.id}
                                                    py="8px"
                                                    mt="8px"
                                                    alignItems="center"
                                                    style={{
                                                        justifyContent: 'space-between',
                                                        gap: '16px'
                                                    }}
                                                >
                                                    <Flex
                                                        width="100%"
                                                        maxWidth="96px"
                                                        alignItems="center"
                                                    >
                                                        <UploadVariantImage
                                                            variantName={color.name}
                                                        />
                                                    </Flex>

                                                    <Flex width="100%">
                                                        <Text>{color.name}</Text>
                                                    </Flex>

                                                    <Flex width="100%" maxWidth="96px">
                                                        <Input
                                                            name="variant_quantity"
                                                            defaultValue={color.quantity}
                                                            size="sm"
                                                            mb="0px"
                                                            onChange={(
                                                                e: ChangeEvent<HTMLInputElement>
                                                            ) =>
                                                                editVariant(
                                                                    color.name,
                                                                    'quantity',
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Flex>

                                                    <Flex width="100%" maxWidth="120px">
                                                        <InputCurrency
                                                            name={`${color.name}_price`}
                                                            defaultValue={color.price}
                                                            size="sm"
                                                            mb="0px"
                                                            onChange={() =>
                                                                handleEditVariant(color.name)
                                                            }
                                                        />
                                                    </Flex>

                                                    <Flex width="100%" maxWidth="80px">
                                                        <Flex
                                                            backgroundColor="red.500"
                                                            color="white"
                                                            width="24px"
                                                            height="24px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            borderRadius="4px"
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                removeVariants(color.name)
                                                            }
                                                        >
                                                            <FaTrash />
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>

                            <Flex flexDirection="column" mt="16px">
                                <Text color="purple.500">Cores</Text>

                                <Flex
                                    border="2px solid"
                                    borderColor="gray.500"
                                    py="8px"
                                    px="16px"
                                    mt="8px"
                                    flexDirection="column"
                                >
                                    {variants?.colors.length === 0 && (
                                        <Text color="gray.700">Nenhuma cor cadastrada</Text>
                                    )}

                                    {variants?.colors.length > 0 && (
                                        <Flex width="100%" flexDirection="column">
                                            <Flex width="100%" justifyContent="space-between">
                                                <Text width="100%" maxWidth="96px">
                                                    Imagem
                                                </Text>
                                                <Text width="100%">Variação</Text>
                                                <Text width="100%" maxWidth="96px">
                                                    Estoque
                                                </Text>
                                                <Text width="100%" maxWidth="120px">
                                                    Preço
                                                </Text>
                                                <Text width="100%" maxWidth="80px">
                                                    Ação
                                                </Text>
                                            </Flex>

                                            {variants?.colors.map((color) => (
                                                <Flex
                                                    key={color.id}
                                                    py="8px"
                                                    mt="8px"
                                                    alignItems="center"
                                                    style={{
                                                        justifyContent: 'space-between',
                                                        gap: '16px'
                                                    }}
                                                >
                                                    <Flex
                                                        width="100%"
                                                        maxWidth="96px"
                                                        alignItems="center"
                                                    >
                                                        <UploadVariantImage
                                                            variantName={color.name}
                                                        />
                                                    </Flex>

                                                    <Flex width="100%">
                                                        <Text>{color.name}</Text>
                                                    </Flex>

                                                    <Flex width="100%" maxWidth="96px">
                                                        <Input
                                                            name="variant_quantity"
                                                            defaultValue={color.quantity}
                                                            size="sm"
                                                            mb="0px"
                                                            onChange={(
                                                                e: ChangeEvent<HTMLInputElement>
                                                            ) =>
                                                                editVariant(
                                                                    color.name,
                                                                    'quantity',
                                                                    e.target.value
                                                                )
                                                            }
                                                        />
                                                    </Flex>

                                                    <Flex width="100%" maxWidth="120px">
                                                        <InputCurrency
                                                            name={`${color.name}_price`}
                                                            defaultValue={color.price}
                                                            size="sm"
                                                            mb="0px"
                                                            onChange={() =>
                                                                handleEditVariant(color.name)
                                                            }
                                                        />
                                                    </Flex>

                                                    <Flex width="100%" maxWidth="80px">
                                                        <Flex
                                                            backgroundColor="red.500"
                                                            color="white"
                                                            width="24px"
                                                            height="24px"
                                                            justifyContent="center"
                                                            alignItems="center"
                                                            borderRadius="4px"
                                                            cursor="pointer"
                                                            onClick={() =>
                                                                removeVariants(color.name)
                                                            }
                                                        >
                                                            <FaTrash />
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                            ))}
                                        </Flex>
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                    )}
                </Flex>

                <Button
                    type="submit"
                    width="100%"
                    backgroundColor="purple.500"
                    height="48px"
                    mt="24px"
                >
                    <Text mr="24px">Cadastrar</Text>

                    <RiSave3Line />
                </Button>
            </Flex>
        </Form>
    );
};

export default ProductsRegister;
